export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBoaFDq38sEJ-qoLFVN9PjUBbZN6jKzsXw';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'dev-online-tatthar.firebaseapp.com';
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || 'dev-online-tatthar';
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'dev-online-tatthar.appspot.com';
export const FIREBASE_MESSAGE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || '746100114822';
export const FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID || '1:746100114822:web:408ff8afc95db2ef051807';
export const FIREBASE_APP_CHECK_KEY =
  process.env.REACT_APP_FIREBASE_APP_CHECK_KEY ||
  '6LeeZeEkAAAAAK1GcZ5KuYcYQpGvMhpMY1jbkcEK';
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-35T2GE00X8';
