import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'lodash';
import { Provider } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { getToken } from 'firebase/app-check';

import store from './redux/store';
// import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';
import { checkUserSession } from './redux/system.slice';
import { firebaseAuth, firebaseAppCheck } from './config/firebase';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function renderReactDOM() {
  try {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('[ERROR] renderReactDOM', error);
  }
}

const updateFirebaseTokens = async () => {
  try {
    const appCheckRes = await getToken(firebaseAppCheck, false);
    localStorage.setItem('firebaseAppCheckToken', appCheckRes.token);
    console.log('[INFO] Updated appCheck token!');

    onAuthStateChanged(firebaseAuth, async (user: any) => {
      if (!!user) {
        const firebaseIdToken = await user.getIdToken(false);
        localStorage.setItem('firebaseIdToken', firebaseIdToken);
        console.log('[INFO] Updated access token!');
      }
    });
  } catch (error) {
    console.error('[ERROR] updateFirebaseTokens', error);
  }
};

updateFirebaseTokens();
setInterval(updateFirebaseTokens, 30 * 60 * 1000);

store.dispatch<any>(checkUserSession()).then(() => {
  renderReactDOM();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
