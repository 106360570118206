import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import RedeemIcon from '@mui/icons-material/Redeem';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AccountOpenBanner from '../AccountOpenBanner';
import ProfileMenu from './ProfileMenu';
import LogoBox from './LogoBox';
import { systemSelector } from '../../redux/system.slice';

export default function Header() {
  const location = useLocation();

  const { isAuth } = useSelector(systemSelector);
  const [currentPath, setCurrentPath] = useState('');

  const isDisabled = true;

  const handleClick = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    setCurrentPath(location.pathname);

    return () => {
      setCurrentPath('');
    };
  }, [location.pathname]);

  return (
    <AppBar
      id='header-app-bar'
      position='fixed'
      sx={{ backgroundColor: '#FFF', padding: '0px 12px' }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <LogoBox />

        <Box
          id='tab-container'
          sx={{
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: ' translate(-50%, -50%)',
            display: { xs: 'flex', md: 'flex' },
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center'
          }}
        >
          <RouterLink
            to={isDisabled ? '' : '/rewards'}
            onClick={isDisabled ? handleClick : undefined}
            style={{
              textDecoration: 'none',
              color: '#000'
            }}
          >
            <Button
              disabled
              size='large'
              sx={{
                height: '100%',
                borderRadius: 0,
                borderBottom:
                  currentPath === '/rewards'
                    ? '4px solid #c62828'
                    : '4px solid transparent',
                color: currentPath === '/rewards' ? '#c62828' : '#000'
              }}
            >
              <RedeemIcon />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    padding: 0,
                    margin: '0px 0px 0px 4px'
                  }}
                >
                  Rewards
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    display: { xs: 'none', md: 'flex' },
                    padding: 0,
                    margin: '0px 0px 0px 4px'
                  }}
                >
                  Coming Soon!
                </Typography>
              </div>
            </Button>
          </RouterLink>

          {isAuth === true && (
            <RouterLink
              to='/leaderboard'
              style={{
                textDecoration: 'none',
                color: '#000'
              }}
            >
              <Button
                size='large'
                sx={{
                  height: '100%',
                  borderRadius: 0,
                  borderBottom:
                    currentPath === '/leaderboard'
                      ? '4px solid #c62828'
                      : '4px solid transparent',
                  color: currentPath === '/leaderboard' ? '#c62828' : '#000'
                }}
              >
                <BarChartIcon />
                <Typography
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    padding: 0,
                    margin: '0px 0px 0px 4px'
                  }}
                >
                  {' '}
                  Leaderboard
                </Typography>
              </Button>
            </RouterLink>
          )}

          <RouterLink
            to='/approvedapps'
            style={{
              textDecoration: 'none',
              color: '#000'
            }}
          >
            <Button
              size='large'
              sx={{
                height: '100%',
                borderRadius: 0,
                borderBottom:
                  currentPath === '/approvedapps'
                    ? '4px solid #c62828'
                    : '4px solid transparent',
                color: currentPath === '/approvedapps' ? '#c62828' : '#000'
              }}
            >
              <FactCheckIcon />
              <Typography
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  padding: 0,
                  margin: '0px 0px 0px 4px'
                }}
              >
                Apps
              </Typography>
            </Button>
          </RouterLink>
        </Box>
        <AccountOpenBanner />
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
