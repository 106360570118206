import { combineReducers } from '@reduxjs/toolkit';
import systemReducer from './system.slice';
// import dialogSliceReducer from '../features/dialogBox.slice';

const rootReducer = combineReducers({
  system: systemReducer
  // dialog: dialogSliceReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
