import React from 'react';

interface CardProps {
  title: string;
  imageUrl: string;
  selected?: boolean;
  onClick?: () => void;
}

const AppMiniCard: React.FC<CardProps> = ({ title, imageUrl, selected = false, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        border: `3px solid ${selected ? '#8e0000' : 'transparent'}`,
        borderRadius: '10px',
        cursor: onClick ? 'pointer' : undefined,
        marginBottom: '8px',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.2)',
        background: 'white'
      }}
      onClick={onClick}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{ width: '100px', height: '100px', marginRight: '20px' }}
      />

      <div>
        <h3 style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>{title}</h3>
      </div>
    </div>
  );
};

export default AppMiniCard;
