import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';

import {
  systemSelector,
  setpageTitle,
  setCurrentUrl
  // setLoading
} from '../redux/system.slice';

export default function AdminDashboard() {
  const dispatch = useDispatch();

  const {
    isAuth
    // isLoading
  } = useSelector(systemSelector);

  return (
    <Grid id='admin-dashboard-container' item xs={12}>
      <Typography
        sx={{
          margin: '12px 0px 12px 0px',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        component='h5'
      >
        Admin Dashboard
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 240
        }}
      >
        This is Admin Dashboard.
      </Paper>
    </Grid>
  );
}
