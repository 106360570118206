import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function AccessRequired() {
  return (
    <div>
      <Typography variant='h6' align='center' color='text.primary' gutterBottom>
        You need to sign up or sign in to access this page.
      </Typography>

      <Stack sx={{ pt: 4 }} direction='row' spacing={2} justifyContent='center'>
        <RouterLink
          to='/signin'
          style={{
            textDecoration: 'none'
          }}
        >
          <Button variant='contained'>Sign In</Button>
        </RouterLink>
        <RouterLink
          to='/signup'
          style={{
            textDecoration: 'none'
          }}
        >
          <Button variant='outlined'>Sign Up</Button>
        </RouterLink>
      </Stack>
    </div>
  );
}
