import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { firebaseAuth } from '../config/firebase';
import { signInUser } from '../services';
import { setNotiData } from '../redux/system.slice';

export default function Signin() {
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(firebaseAuth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage('');

    const serviceRes: any = await signInUser({ email, password });
    if (serviceRes.isSucceeded) {
      navigate('/leaderboard');
      dispatch(setNotiData({ message: 'Sign in succssful!', severity: 'success' }));
    } else {
      switch (serviceRes.messageType) {
        case 'FORM':
          setErrorMessage(serviceRes.message);
          break;
        case 'NOTIFICATION':
        default:
          dispatch(
            setNotiData({
              message: 'Something went wrong. Please try again.',
              severity: 'error'
            })
          );
          break;
      }
    }
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate('/leaderboard');
  }, [user, loading, navigate]);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box
          component='form'
          onSubmit={(e: any) => e.preventDefault()}
          noValidate
          sx={{ mt: 1 }}
        >
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}

          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to='/forgotpassword'>
                <Typography variant='body2'>Forgot password?</Typography>
              </RouterLink>
            </Grid>
            <Grid item>
              <RouterLink to='/signup'>
                <Typography variant='body2'> Don't have an account? Sign Up</Typography>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
