// REF: https://blog.logrocket.com/using-typescript-with-redux-toolkit/

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onAuthStateChanged } from 'firebase/auth';

import { AppThunk } from './store';
import { RootState } from './rootReducer';
import { getUserWithoutAxiosCancel } from '../services';
import { IFirebaseUser, IOttUser } from '../interfaces';
import { firebaseAuth } from '../config/firebase';

export interface SystemError {
  message: string;
}

interface INotiData {
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error' | '';
}

export interface ISystemState {
  isAuth: boolean;
  isAdminUser: boolean;
  ottUser?: IOttUser;
  firebaseUser?: IFirebaseUser;
  currentUrl?: string;
  pageTitle?: string;
  isLoading: boolean;
  error: SystemError;
  isNotiBarOpen: boolean;
  notiData?: INotiData;
  isPhotoFormOpen: boolean;
  isNameFormOpen: boolean;
  isEmailFormOpen: boolean;
  isPasswordFormOpen: boolean;
}

export const initialState: ISystemState = {
  isAuth: false,
  isAdminUser: false,
  isLoading: true,
  isNotiBarOpen: false,
  error: { message: 'An Error occurred' },
  isPhotoFormOpen: false,
  isNameFormOpen: false,
  isEmailFormOpen: false,
  isPasswordFormOpen: false
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setLoading: (state: ISystemState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setCurrentUrl: (state: ISystemState, { payload }: PayloadAction<string>) => {
      state.currentUrl = payload;
    },
    setpageTitle: (state: ISystemState, { payload }: PayloadAction<string>) => {
      state.pageTitle = payload;
    },
    setOttUser: (state, { payload }: PayloadAction<IOttUser>) => {
      state.ottUser = payload;
    },
    setFirebaseUser: (state, action: PayloadAction<IFirebaseUser>) => {
      state.firebaseUser = action.payload;
    },
    setNotiData: (state, action: PayloadAction<INotiData>) => {
      state.isNotiBarOpen = true;
      state.notiData = action.payload;
    },
    setDisableNotiBar: (state, { payload }: PayloadAction) => {
      state.isNotiBarOpen = false;
    },
    setPhotoFormDisplay: (state: ISystemState, { payload }: PayloadAction<boolean>) => {
      state.isPhotoFormOpen = payload;
    },
    setNameFormDisplay: (state: ISystemState, { payload }: PayloadAction<boolean>) => {
      state.isNameFormOpen = payload;
    },
    setEmailFormDisplay: (state: ISystemState, { payload }: PayloadAction<boolean>) => {
      state.isEmailFormOpen = payload;
    },
    setPasswordFormDisplay: (
      state: ISystemState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPasswordFormOpen = payload;
    },
    setAuthSuccess: (state, { payload }: PayloadAction) => {
      state.isAuth = true;
    },
    setAdminAuthSuccess: (state, { payload }: PayloadAction) => {
      state.isAdminUser = true;
    },
    setLogOut: state => {
      state.isAuth = false;
      state.ottUser = undefined;
      state.firebaseUser = undefined;
      localStorage.removeItem('firebaseIdToken');
    },
    setSystemFailed: (state, { payload }: PayloadAction<SystemError>) => {
      state.error = payload;
      state.isAuth = false;
    }
  }
});

export const checkUserSession = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));

    console.log('[INFO] Checking user session...');
    await onAuthStateChanged(firebaseAuth, async (fUser: any) => {
      if (!fUser) {
        console.log('[INFO] No user session');
        return;
      }
      if (!!fUser) {
        dispatch(setAuthSuccess());
        const token = await fUser.getIdToken(false);

        if (firebaseAuth && firebaseAuth.currentUser) {
          firebaseAuth.currentUser
            .getIdTokenResult()
            .then(idTokenResult => {
              if (!!idTokenResult.claims.admin) {
                // console.log(' admin');
                dispatch(setAdminAuthSuccess());
              }
            })
            .catch(error => {
              console.error('[ERROR] getIdTokenResult', error);
            });
        }

        localStorage.setItem('firebaseAccessToken', token);

        const fUserData: IFirebaseUser = {
          uid: fUser.uid,
          email: fUser.email,
          emailVerified: fUser.emailVerified,
          displayName: fUser.displayName,
          photoURL: fUser.photoURL
        };
        dispatch(setFirebaseUser(fUserData));

        const oUser = await getUserWithoutAxiosCancel(token);
        if (oUser.isSucceeded) {
          dispatch(setOttUser(oUser.itemDetails));
        }
        console.log('[INFO] Updated user profile and session!');
      }
    });
  } catch (error: any) {
    console.error('[ERROR] checkUserSession', error);
    dispatch(setSystemFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export default systemSlice.reducer;

export const {
  setAuthSuccess,
  setAdminAuthSuccess,
  setOttUser,
  setFirebaseUser,
  setNotiData,
  setDisableNotiBar,
  setLogOut,
  setLoading,
  setSystemFailed,
  setCurrentUrl,
  setpageTitle,
  setPhotoFormDisplay,
  setNameFormDisplay,
  setEmailFormDisplay,
  setPasswordFormDisplay
} = systemSlice.actions;

export const systemSelector = (state: RootState) => state.system;
