import React, { useEffect } from 'react';
import { Outlet, Link as RouterLink, useNavigate, Navigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from 'firebase/auth';

import './App.css';
import { firebaseAuth } from './config/firebase';
import { setFirebaseUser } from './redux/system.slice';
import AccessRequired from './pages/accessRequired';
import AdminDashboard from './pages/adminDashboard';
import AdminDashboardLayout from './layouts/AdminDashboardLayout';
import UserDashboardLayout from './layouts/UserDashboardLayout';
import LandingLayout from './layouts/LandingLayout';
import ProtectedUserRoute from './protectedUserRoute';
import ProtectedAdminRoute from './protectedAdminRoute';
import PageNotFound from './pages/pageNotFound';
import ApprovedApps from './pages/approvedApps';
import ApprovedAppDetails from './pages/approvedAppDetails';
import ChangeEmail from './pages/changeEmail';
import ChangeName from './pages/changeName';
import ChangePassword from './pages/changePassword';
import ChangeProfilePhoto from './pages/changeProfilePhoto';
import ForgotPassword from './pages/forgotPassword';
import UserDashboard from './pages/userDashboard';
import LandingPage from './pages/landingPage';
import Leaderboard from './pages/leaderboard';
import Profile from './pages/profile';
import Redemptions from './pages/redemptions';
import Rewards from './pages/rewards';
import ScorePoints from './pages/scorePoints';
import Signin from './pages/signin';
import Signup from './pages/signup';
import Users from './pages/users';
import StatusPopover from './components/StatusPopover';

function App() {
  // let navigate = useNavigate();
  // const dispatch = useDispatch();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path=''
            element={<ProtectedAdminRoute outlet={<AdminDashboardLayout />} />}
          >
            <Route path='/users' element={<Users />} />
            <Route path='/adminDashboard' element={<AdminDashboard />} />
          </Route>

          <Route element={<UserDashboardLayout />}>
            <Route index element={<LandingPage />} />
            <Route path='/signin' element={<Signin />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/accessrequired' element={<AccessRequired />} />
            <Route path='/approvedapps' element={<ApprovedApps />} />
            <Route path='/approvedapps/:appId' element={<ApprovedAppDetails />} />
            <Route path='/userdashboard' element={<UserDashboard />} />
            {/* <Route path='/rewards' element={<Rewards />} /> */}
            <Route path='*' element={<PageNotFound />} />
          </Route>

          <Route
            path=''
            element={<ProtectedUserRoute outlet={<UserDashboardLayout />} />}
          >
            <Route path='/changeemail' element={<ChangeEmail />} />
            <Route path='/changename' element={<ChangeName />} />
            <Route path='/changepassword' element={<ChangePassword />} />
            <Route path='/changeprofilephoto' element={<ChangeProfilePhoto />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/leaderboard' element={<Leaderboard />} />
            <Route path='/redemptions' element={<Redemptions />} />
            <Route path='/scorepoints' element={<ScorePoints />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <StatusPopover /> */}
    </>
  );
}

export default App;
