import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { firebaseAuth } from '../config/firebase';
import { firebaseUpdateEmail } from '../services';
import {
  systemSelector,
  setNotiData,
  checkUserSession,
  setEmailFormDisplay
} from '../redux/system.slice';

export default function ChangeEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, firebaseUser, isEmailFormOpen } = useSelector(systemSelector);

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (email === '') {
        setErrorMessage("Email can't be empty");
        return;
      }

      const serviceRes: any = await firebaseUpdateEmail(email);
      if (serviceRes.isSucceeded) {
        dispatch(setEmailFormDisplay(false));
        dispatch<any>(checkUserSession());
        dispatch(setNotiData({ message: 'Update successful!', severity: 'success' }));
      } else {
        setErrorMessage(serviceRes.message);
        // dispatch(
        //   setNotiData({
        //     message: '',
        //     severity: 'error'
        //   })
        // );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser.email !== '') {
      setEmail(firebaseUser.email);
    }
  }, [firebaseUser]);

  return (
    <Grid item xs={12} sx={{ marginTop: '12px' }}>
      <Dialog
        open={isEmailFormOpen}
        onClose={() => {
          dispatch(setEmailFormDisplay(false));
        }}
        maxWidth={'lg'}
      >
        <DialogTitle>Change Email</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin='normal'
            required
            fullWidth
            id='email'
            label='New Email'
            name='email'
            autoComplete='email'
            variant='standard'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setEmailFormDisplay(false));
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
