import { createTheme } from '@mui/material/styles';
// import { red } from '@mui/material/colors';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1216
    }
  },
  spacing: 4,
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#ff5f52',
      main: '#c62828',
      dark: '#8e0000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffcccb',
      main: '#ef9a9a',
      dark: '#ba6b6c',
      contrastText: '#000000'
    }
  }
});

export default theme;
