import React, { useEffect, useState } from 'react';
import {
  useParams,
  useSearchParams,
  // useLocation,
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';

import { firebaseAuth } from '../config/firebase';
import { getRegions, signUpUser } from '../services';
import { setOttUser, setAuthSuccess, setNotiData } from '../redux/system.slice';

import kitten1 from '../images/kitten1.png';
import kitten2 from '../images/kitten2.png';
import rabbit1 from '../images/avatar_half_body_rabbit-01.jpeg';
import rabbit2 from '../images/avatar_half_body_rabbit-02.jpeg';
import rabbit3 from '../images/avatar_half_body_rabbit-03.jpeg';
import imgMissionZones from '../images/missionZones.jpg';

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  let referralCode = searchParams.get('referralCode') || '';

  const [user, loading, error] = useAuthState(firebaseAuth);

  const [name, setName] = useState('');
  const [refCode, setRefCode] = useState(referralCode || '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isMapOpen, setIsMapOpen] = React.useState(false);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const handleClickMapOpen = () => {
    setIsMapOpen(true);
  };

  const handleClickMapClose = () => {
    setIsMapOpen(false);
  };

  // const handlePickImage = () => {
  //   setImageUrl
  // }

  const handleSubmit = async () => {
    setErrorMessage('');

    if (name === '') {
      setErrorMessage('Name is required.');
      return;
    }
    if (email === '') {
      setErrorMessage('Email is required.');
      return;
    }
    if (password === '') {
      setErrorMessage('Password is required.');
      return;
    }

    // if (!displayName) alert('Please enter Name');
    const serviceRes: any = await signUpUser({
      name,
      email,
      password,
      imageUrl,
      referralCode: refCode,
      region
    });
    if (serviceRes.isSucceeded) {
      navigate('/leaderboard');
      dispatch(setNotiData({ message: 'Sign up succssful!', severity: 'success' }));
    } else {
      switch (serviceRes.messageType) {
        case 'FORM':
          setErrorMessage(serviceRes.message);
          break;
        case 'NOTIFICATION':
        default:
          dispatch(
            setNotiData({
              message: 'Something went wrong. Please try again.',
              severity: 'error'
            })
          );
          break;
      }
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) navigate('/leaderboard');
  }, [user, loading, navigate]);

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();
    const axiosCancelToken = axiosSource.token;

    const fetchRegions = async () => {
      const serviceRes: any = await getRegions();
      if (serviceRes.isSucceeded) {
        setRegions(serviceRes.itemList);
        setRegion(serviceRes.itemList[0]._id)
      } else {
        console.log('[INFO] No Apps Data');
      }
      setIsDataLoaded(true);
    };

    if (!isDataLoaded) {
      fetchRegions();
    };

  }, [isDataLoaded])

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Dialog open={isMapOpen} onClose={handleClickMapClose}>
        <DialogTitle>Mission Zones</DialogTitle>
        <DialogContent>
          <img
            width='280'
            height='411'
            src={imgMissionZones}
            alt='Profile'
            onClick={() => {
              setImageUrl('rabbit3');
            }}
            style={{
              margin: '0px 0px 0px 8px',
              border: imageUrl === 'rabbit3' ? '3px solid #c62828' : '3px solid #fff',
              borderRadius: '12px'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickMapClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <Box
          component='form'
          noValidate
          onSubmit={(e: any) => e.preventDefault()}
          sx={{ mt: 5 }}
        >
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ mb: 1 }}
              container
              flexDirection={'column'}
              justifyContent='flex-start'
              alignItems={'center'}
            >
              <InputLabel id='profile-photo-label'>Select Profile Photo</InputLabel>
              <Box>
                <img
                  height='80'
                  width='80'
                  src={rabbit1}
                  alt='Profile'
                  onClick={() => {
                    setImageUrl('rabbit1');
                  }}
                  style={{
                    margin: '0px ',
                    border:
                      imageUrl === 'rabbit1' ? '3px solid #c62828' : '3px solid #fff',
                    borderRadius: '12px'
                  }}
                />
                <img
                  height='80'
                  width='80'
                  src={rabbit2}
                  alt='Profile'
                  onClick={() => {
                    setImageUrl('rabbit2');
                  }}
                  style={{
                    margin: '0px 0px 0px 8px',
                    border:
                      imageUrl === 'rabbit2' ? '3px solid #c62828' : '3px solid #fff',
                    borderRadius: '12px'
                  }}
                />
                <img
                  height='80'
                  width='80'
                  src={rabbit3}
                  alt='Profile'
                  onClick={() => {
                    setImageUrl('rabbit3');
                  }}
                  style={{
                    margin: '0px 0px 0px 8px',
                    border:
                      imageUrl === 'rabbit3' ? '3px solid #c62828' : '3px solid #fff',
                    borderRadius: '12px'
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <TextField
                autoComplete='given-name'
                name='name'
                required
                fullWidth
                id='name'
                label='Name'
                autoFocus
                onChange={e => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <TextField
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='new-password'
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <Select
                  value={region}
                  onChange={e => setRegion(e.target.value)}
                >
                  {
                    regions.length > 0 && regions.map((region: any) => (
                      <MenuItem value={region._id}>{region.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <TextField
                disabled={referralCode === '' ? false : true}
                autoComplete='given-name'
                name='refCode'
                fullWidth
                id='refCode'
                label='Referral Code'
                defaultValue={refCode}
                onChange={e => setRefCode(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Sign Up
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <RouterLink to='/signin'>
                <Typography variant='body2'>Already have an account? Sign in</Typography>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
