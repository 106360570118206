import React, { useCallback, useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Stack } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { ENABLE_AXIOS_CANCEL_TOKEN, POINTS_OPTIONS } from '../../constants';
import { setpageTitle } from '../../redux/system.slice';
import {
  getRegionalTopUsers,
  getTopUsers,
  getTopUsersPerApp,
  getTopUsersPerAppPerRegion
} from '../../services';

interface ILeaderboardTabProps {
  tabIndex: number;
  tabValue: number;
  regionName: string;
  regionId: string;
  pointsOption: string;
  appId: string;
}

const goldGlitter = keyframes`
  0% {
    color: gold;
  }
  50% {
    color: #ffd700;
  }
  100% {
    color: gold;
  }
`;

const silverGlitter = keyframes`
  0% {
    color: silver;
  }
  50% {
    color: #c0c0c0;
  }
  100% {
    color: silver;
  }
`;

const bronzeGlitter = keyframes`
  0% {
    color: peru;
  }
  50% {
    color: #cd853f;
  }
  100% {
    color: peru;
  }
`;

export default function LeaderboardTab(props: ILeaderboardTabProps) {
  const dispatch = useDispatch();
  const axiosSource = axios.CancelToken.source();
  const axiosCancelToken = axiosSource.token;
  const { regionId, regionName, tabValue, tabIndex, pointsOption, appId, ...other } =
    props;

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [rows, setRows] = useState([]);

  // initialize to Total Points
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const fetchRegionalTopUsers = useCallback(async () => {
    const serviceRes: any = await getRegionalTopUsers(axiosCancelToken, {
      regionId: regionId,
      pointType: pointsOption
    });
    if (serviceRes.isSucceeded) {
      setRows(serviceRes.itemList);
    } else {
      console.log('[INFO] No top users per region');
    }
    setIsDataLoaded(true);
  }, [pointsOption]);

  const fetchGlobalTopUsers = useCallback(async () => {
    const serviceRes: any = await getTopUsers(axiosCancelToken, {
      pointType: pointsOption
    });
    if (serviceRes.isSucceeded) {
      setRows(serviceRes.itemList);
    } else {
      console.log('[INFO] No top users per region');
    }
    setIsDataLoaded(true);
  }, [pointsOption]);

  const fetchGlobalTopUsersPerApp = useCallback(async () => {
    const serviceRes: any = await getTopUsersPerApp(axiosCancelToken, {
      appId: appId,
      pointType: pointsOption
    });
    console.log('HELLo');
    if (serviceRes.isSucceeded) {
      setRows(serviceRes.itemList);
    } else {
      console.log('[INFO] No top users per region');
    }
    setIsDataLoaded(true);
  }, [pointsOption, appId]);

  const fetchGlobalTopUsersPerAppPerRegion = useCallback(async () => {
    const serviceRes: any = await getTopUsersPerAppPerRegion(axiosCancelToken, {
      regionId: regionId,
      appId: appId,
      pointType: pointsOption
    });
    if (serviceRes.isSucceeded) {
      setRows(serviceRes.itemList);
    } else {
      console.log('[INFO] No top users per region');
    }
    setIsDataLoaded(true);
  }, [pointsOption, appId, regionId]);

  function stringToColor(string: any) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: any) {
    return name
      ? {
          sx: {
            bgcolor: stringToColor(name)
          },
          children: `${name.split(' ')[0][0]}`
        }
      : {};
  }

  useEffect(() => {
    if (tabValue === 0 && regionId === 'global' && !appId) fetchGlobalTopUsers();
    else if (tabValue > 0 && regionId !== 'global' && !appId) fetchRegionalTopUsers();
    else if (tabValue === 0 && regionId === 'global' && appId)
      fetchGlobalTopUsersPerApp();
    else if (tabValue > 0 && regionId !== 'global' && appId)
      fetchGlobalTopUsersPerAppPerRegion();

    return () => {
      axiosSource.cancel();
    };
  }, [tabValue, regionId, pointsOption, appId]);

  // useEffect(() => {

  //   if (!isDataLoaded) {
  //     if (pointsOption === POINTS_OPTIONS[0]) fetchRegionalTopUsersTotalPoints();
  //     else fetchRegionalTopUsersTitlePoints();
  //   }

  //   return () => {
  //     if (ENABLE_AXIOS_CANCEL_TOKEN === 'TRUE') {
  //       axiosSource.cancel();
  //     }
  //   };
  // }, [isDataLoaded, regionId, pointsOption]);

  if (regionId === '') {
    return null;
  }

  return (
    <>
      <Box
        role='tabpanel'
        hidden={tabValue !== tabIndex}
        id={`leaderboard-tabpanel-${tabIndex}`}
        aria-labelledby={`leaderboard-tab-${tabIndex}`}
        {...other}
      >
        {tabValue === tabIndex && (
          <Grid
            container
            alignItems={'flex-start'}
            alignContent={'flex-start'}
            justifyItems={'flex-start'}
          >
            {/* <Typography
              sx={{
                margin: '12px 0px 12px 0px',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
              component='h5'
            >
              {regionName}
            </Typography> */}

            {rows.length < 0 && <Typography>No Top Users yet!</Typography>}
            {rows.length > 0 && (
              <>
                {
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 300,
                        fontFamily: 'Roboto Mono, monospace',
                        fontWeight: 'bold'
                      }}
                      aria-label='simple table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: 'bold', fontSize: 16, width: 100 }}
                          >
                            Rank
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 'bold', paddingLeft: 16, fontSize: 16 }}
                          >
                            Tatthar
                          </TableCell>
                          {pointsOption === POINTS_OPTIONS[0] && (
                            <TableCell
                              sx={{ fontWeight: 'bold', paddingLeft: 16, fontSize: 16 }}
                            >
                              Rank
                            </TableCell>
                          )}
                          <TableCell
                            sx={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: 16 }}
                            align='right'
                          >
                            {pointsOption === POINTS_OPTIONS[0]
                              ? 'Total Points'
                              : 'Weekly Points'}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: any, index: number) => {
                          const numberHighlight =
                            index === 0
                              ? { color: 'gold', fontSize: 18 }
                              : index === 1
                              ? { color: 'silver', fontSize: 18 }
                              : index === 2
                              ? { color: 'peru', fontSize: 18 }
                              : { fontSize: 16 };

                          return (
                            <TableRow
                              key={row._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell
                                component='th'
                                scope='row'
                                sx={{ fontWeight: 'bold', ...numberHighlight }}
                              >
                                {index + 1}
                                {index < 3 && (
                                  <TrophyIcon
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: 'inherit',
                                      verticalAlign: 'middle',
                                      color: numberHighlight.color
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 10
                                  }}
                                >
                                  <div>
                                    <Avatar {...stringAvatar(row.name)} />
                                  </div>
                                  <div style={{ fontSize: 16 }}>{row.name}</div>
                                </div>
                              </TableCell>
                              {pointsOption === POINTS_OPTIONS[0] && (
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{
                                    fontSize: 16,
                                    fontStyle: 'italic',
                                    fontWeight: 500
                                  }}
                                >
                                  {row.rank?.name}
                                </TableCell>
                              )}
                              <TableCell style={{ fontSize: 16 }} align='right'>
                                {pointsOption === POINTS_OPTIONS[0]
                                  ? row.totalPoints
                                  : row.titlePoints}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
}