import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import _ from 'lodash';
import axios from 'axios';
import {
  // useLocation,
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useAuthState } from 'react-firebase-hooks/auth';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { firebaseAuth } from '../config/firebase';
import { firebaseUpdateEmail } from '../services';
import RedemptionHistory from '../components/RedemptionHistory';
import ConnectedApps from '../components/ConnectedApps';
import UserRewards from '../components/UserRewards';
import { getTimestamptString } from '../utils/timestamp.util';
import { ENABLE_AXIOS_CANCEL_TOKEN, OTT_DOMAIN } from '../constants';
import {
  systemSelector,
  setNotiData,
  setPhotoFormDisplay,
  setNameFormDisplay,
  setEmailFormDisplay
} from '../redux/system.slice';

import kitten1 from '../images/kitten1.png';
import kitten2 from '../images/kitten2.png';
import rabbit1 from '../images/avatar_half_body_rabbit-01.jpeg';
import rabbit2 from '../images/avatar_half_body_rabbit-02.jpeg';
import rabbit3 from '../images/avatar_half_body_rabbit-03.jpeg';

const ottUserDefaultValues = {
  _id: '',
  referralCode: '',
  rank: '',
  region: '',
  totalPoints: 0,
  usedPoints: 0,
  availablePoints: 0,
  apps: [],
  rewards: [],
  createdAt: ''
};

const firebaseUserDefaultValues = {
  displayName: '',
  email: '',
  emailVerified: false,
  uid: ''
};

export default function Profile() {
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(firebaseAuth);
  const { isAuth, firebaseUser, ottUser } = useSelector(systemSelector);

  const [ottUserDetails, setOttUserDetails] = useState(ottUserDefaultValues);
  const [firebaseUserDetails, setFirebaseUserDetails] = useState(
    firebaseUserDefaultValues
  );

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [photo, setPhoto] = useState<any>(null);

  console.log(ottUserDetails)

  const copyReferralCode = (code: string) => {
    navigator.clipboard.writeText(code);
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2000);
  };

  const copyReferralLink = (code: string) => {
    navigator.clipboard.writeText(`${OTT_DOMAIN}/signup?referralCode=${code}`);
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2000);
  };

  useEffect(() => {
    const ottUserData: any = {};
    ottUserData._id = ottUser && ottUser._id ? ottUser._id : '';
    ottUserData.referralCode =
      ottUser && ottUser.referralCode ? ottUser.referralCode : '';
    ottUserData.rank = ottUser && ottUser.rank ? ottUser.rank : '';
    ottUserData.region =
      ottUser && ottUser.region && ottUser.region.name ? ottUser.region.name : '';
    ottUserData.totalPoints = ottUser && ottUser.totalPoints ? ottUser.totalPoints : 0;
    ottUserData.usedPoints = ottUser && ottUser.usedPoints ? ottUser.usedPoints : 0;
    ottUserData.availablePoints = ottUserData.totalPoints - ottUserData.usedPoints;
    ottUserData.apps = ottUser && ottUser.apps && ottUser.apps.length ? ottUser.apps : [];
    ottUserData.rewards =
      ottUser && ottUser.rewards && ottUser.rewards.length ? ottUser.rewards : [];
    ottUserData.createdAt =
      ottUser && ottUser.createdAt
        ? getTimestamptString(ottUser.createdAt, 'DD MMM YYYY hh:mm A')
        : '';

    setOttUserDetails(ottUserData);
  }, [ottUser]);

  useEffect(() => {
    if (!!firebaseUser && !!firebaseUser.photoURL) {
      switch (firebaseUser.photoURL) {
        case 'rabbit1':
          setPhoto(rabbit1);
          break;
        case 'rabbit2':
          setPhoto(rabbit2);
          break;
        case 'rabbit3':
          setPhoto(rabbit3);
          break;
        default:
          break;
      }
    }

    const firebaseUserData: any = {};
    firebaseUserData.displayName =
      firebaseUser && firebaseUser.displayName ? firebaseUser.displayName : '';
    firebaseUserData.email = firebaseUser && firebaseUser.email ? firebaseUser.email : '';
    firebaseUserData.emailVerified =
      firebaseUser && firebaseUser.emailVerified ? firebaseUser.emailVerified : false;
    firebaseUserData.uid = firebaseUser && firebaseUser.uid ? firebaseUser.uid : '';

    setFirebaseUserDetails(firebaseUserData);
  }, [firebaseUser]);

  return (
    <Box
      id='profile-content'
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: '40px'
      }}
    >
      {/* <Typography
        align='left'
        variant='h6'
        sx={{ width: '100%', margin: '4px 0px 24px 0px' }}
      >
        User Profle
      </Typography> */}

      <Box
        sx={{
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          width: '100%',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
            component='h5'
          >
            Profile Picture
          </Typography>

          <Button
            size='small'
            variant='text'
            onClick={() => {
              dispatch(setPhotoFormDisplay(true));
            }}
          >
            Edit
          </Button>
        </div>
        <Box
          id='profile-pic-container'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <img
            height='160'
            width='160'
            src={photo}
            alt='Profile'
            style={{ border: '1px solid #e5e5e5', borderRadius: '12px' }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          width: '100%',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
            component='h5'
          >
            Referral Code
          </Typography>

          <Tooltip
            disableHoverListener
            TransitionComponent={Zoom}
            leaveDelay={600}
            title='Copied!'
            open={isTooltipOpen}
          >
            <Box>
              <Button
                size='small'
                variant='text'
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  copyReferralCode(ottUserDetails.referralCode);
                }}
              >
                Copy Code
              </Button>
              <Button
                size='small'
                variant='text'
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  copyReferralLink(ottUserDetails.referralCode);
                }}
              >
                Copy Link
              </Button>
            </Box>
          </Tooltip>
        </div>

        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {ottUserDetails.referralCode}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          width: '100%',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
            component='h5'
          >
            Name
          </Typography>

          <Button
            size='small'
            variant='text'
            onClick={() => {
              dispatch(setNameFormDisplay(true));
            }}
          >
            Edit
          </Button>
        </div>

        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {firebaseUserDetails.displayName}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          width: '100%',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
            component='h5'
          >
            Email
          </Typography>
          {/* <Button
            size='small'
            variant='text'
            onClick={() => {
              dispatch(setEmailFormDisplay(true));
            }}
          >
            Edit
          </Button> */}
        </div>

        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {firebaseUserDetails.email}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Total Earned Points
        </Typography>
        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {ottUserDetails.totalPoints}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Available Points
        </Typography>
        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {ottUserDetails.availablePoints}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Used Points
        </Typography>
        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {ottUserDetails.usedPoints}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Rank
        </Typography>
        <Stack
          direction='row'
          justifyContent={'center'}
          spacing={1}
          sx={{ width: '100%', margin: '4px 0px ' }}
        >
          {ottUserDetails.rank !== '' && (
            <Chip label={ottUserDetails.rank} variant='outlined' />
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Region
        </Typography>
        <Stack
          direction='row'
          justifyContent={'center'}
          spacing={1}
          sx={{ width: '100%', margin: '4px 0px ' }}
        >
          {ottUserDetails.region}
        </Stack>
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: '0px 0px 8px 0px',
          margin: '0px 0px 4px 0px',
          borderBottom: '1px solid #ffe1e1'
        }}
      >
        <Typography
          sx={{ margin: '8px 0px 4px 0px', fontSize: '16px', fontWeight: 'bold' }}
          component='h5'
        >
          Joining Date
        </Typography>
        <Typography align='center' sx={{ width: '100%', margin: '4px 0px ' }}>
          {ottUserDetails.createdAt}
        </Typography>
      </Box>

      {ottUserDetails.apps.length > 0 && <ConnectedApps appList={ottUserDetails.apps} />}
      {ottUserDetails.rewards.length > 0 && (
        <UserRewards rewardList={ottUserDetails.rewards} />
      )}
    </Box>
  );
}
