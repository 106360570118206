import React, { useEffect, useState } from 'react';
import {
  // useLocation,
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ref, getDownloadURL } from 'firebase/storage';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { visuallyHidden } from '@mui/utils';

import { ENABLE_AXIOS_CANCEL_TOKEN } from '../constants';
import { firebaseAuth, firebaseStorage } from '../config/firebase';
import { setpageTitle } from '../redux/system.slice';
import { getApps } from '../services';
import AppCard from '../components/AppCard';

export default function ApprovedApps() {
  const dispatch = useDispatch();

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();
    const axiosCancelToken = axiosSource.token;

    const fetchApps = async () => {
      const serviceRes: any = await getApps(axiosCancelToken);
      if (serviceRes.isSucceeded) {
        setRows(serviceRes.itemList);
      } else {
        console.log('[INFO] No Apps Data');
      }
      setIsDataLoaded(true);
    };

    if (!isDataLoaded) fetchApps();

    return () => {
      if (ENABLE_AXIOS_CANCEL_TOKEN === 'TRUE') {
        axiosSource.cancel();
      }
    };
  }, [isDataLoaded]);

  useEffect(() => {
    async function setImageUrls(items: any) {
      items.forEach((item: any) => {
        if (item.logo) {
          getDownloadURL(ref(firebaseStorage, item.logo)).then(url => {
            const img = document.getElementById(`logo-${item._id}`);
            if (img) img.setAttribute('src', url);
          });
        }
      });
    }
    if (rows.length) {
      setImageUrls(rows);
    }
  }, [rows]);

  return (
    <Grid
      container
      spacing='12'
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      justifyItems={'flex-start'}
      sx={{ marginTop: '12px' }}
    >
      <Typography
        sx={{
          margin: '12px 0px 12px 0px',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        component='h5'
      >
        ApprovedApps
      </Typography>
      {/* <Paper
        sx={{
          p: 2,
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 240
        }}
      >
        {rows.length < 0 && <Typography>No Apps Data yet!</Typography>}
       
      </Paper> */}
      {rows.length > 0 && (
        <Grid container spacing={4}>
          {rows.map((row: any, index: number) => (
            <Grid item key={`item-${index}`} xs={12} sm={12} md={6} lg={3}>
              <Card
                key={`card-${index}`}
                sx={{
                  // maxWidth: 345
                  // margin: '4px'
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                {/* <CardActionArea> */}
                <CardMedia
                  id={`logo-${row._id}`}
                  component='img'
                  alt={row.name}
                  sx={{
                    display: 'blcok',
                    padding: '0px',
                    margin: '0px auto',
                    width: '140px',
                    height: '140px'
                  }}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography gutterBottom variant='body1'>
                    {row.name}
                  </Typography>
                  {/* <Typography variant='body2' color='text.secondary'>
                    {row.description}
                  </Typography> */}
                </CardContent>
                {/* </CardActionArea> */}
                <CardActions>
                  <RouterLink
                    to={`/approvedapps/${row._id}`}
                    style={{
                      textDecoration: 'none',
                      color: '#000'
                    }}
                  >
                    <Button size='small' color='primary'>
                      View Details
                    </Button>
                  </RouterLink>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
