import axios from 'axios';
// import { getToken  } from "firebase/app-check";

import { firebaseAuth } from '../config/firebase';
import { OTT_SERVER } from '../constants';
import { IResponse } from '../interfaces';

export const getRegionalTopUsers = async (
  axiosCancelToken: any,
  payload: any
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseIdToken = localStorage.getItem('firebaseIdToken') || '';
    // if (firebaseAuth.currentUser) {
    //   firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    // }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/ranking/users/regions/${payload.regionId}/${payload.pointType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      },
    });

    if (!res.data) {
      resData.message = 'No Top Users per Region.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getRegionalTopUsers aborted successfully!');
    } else {
      console.error('[ERROR] getRegionalTopUsers:', error);
    }
    resData.error = error;
    return resData;
  }
};


export const getTopUsers = async (axiosCancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseIdToken = localStorage.getItem('firebaseIdToken') || '';
    // if (firebaseAuth.currentUser) {
    //   firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    // }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/ranking/users/top10/${payload.pointType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      },
    });

    if (!res.data) {
      resData.message = 'No Top Users.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data.users;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getTopUsers aborted successfully!');
    } else {
      console.error('[ERROR] getTopUsers:', error);
    }
    resData.error = error;
    return resData;
  }
};


export const getTopUsersPerApp = async (axiosCancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseIdToken = localStorage.getItem('firebaseIdToken') || '';
    // if (firebaseAuth.currentUser) {
    //   firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    // }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/ranking/users/apps/${payload.appId}/top10/${payload.pointType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      },
    });
    console.log(res.data)

    if (!res.data) {
      resData.message = 'No Top Users.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getTopUsers aborted successfully!');
    } else {
      console.error('[ERROR] getTopUsers:', error);
    }
    resData.error = error;
    return resData;
  }
};


export const getTopUsersPerAppPerRegion = async (axiosCancelToken: any, payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseIdToken = localStorage.getItem('firebaseIdToken') || '';
    // if (firebaseAuth.currentUser) {
    //   firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    // }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/ranking/users/apps/${payload.appId}/regions/${payload.regionId}/top10/${payload.pointType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      },
    });
    console.log(res.data)

    if (!res.data) {
      resData.message = 'No Top Users.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getTopUsers aborted successfully!');
    } else {
      console.error('[ERROR] getTopUsers:', error);
    }
    resData.error = error;
    return resData;
  }
};