import * as React from 'react';
import { Outlet, Link as RouterLink, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';

import Header from '../../components/Header';
import MainTheme from '../../mainTheme';
import NotiBar from '../../components/NotiBar';
import ChangeProfilePhoto from '../../pages/changeProfilePhoto';
import ChangeName from '../../pages/changeName';
import ChangeEmail from '../../pages/changeEmail';
import ChangePassword from '../../pages/changePassword';

export default function UserDashboardLayout() {
  return (
    <ThemeProvider theme={MainTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column',}}>
        <CssBaseline />
        <Header />

        <Box
          component='main'
          // bgcolor = "green"
          width="100%"
          sx={{
            background:'none',
            // backgroundColor: theme =>
            //   theme.palette.mode === 'light'
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],

            backgroundColor: "#f5f5f5",
            opacity: "0.9",
            backgroundImage:  "radial-gradient(#ff5f52 0.25px, transparent 0.25px), radial-gradient(#ff5f52 0.25px, #f5f5f5 0.25px)",
            backgroundSize: "10px 10px",
            backgroundPosition: "0 0,5px 5px",
           
            overflow: 'auto'
          }}
        >
          <Toolbar />
          <NotiBar />

          <Container
            maxWidth='lg'
            sx={{ mt: 0, minWidth: '360px' }}
        
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'flex-start',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minHeight: '100vh',
              width:'100%'
            }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>

      <ChangeProfilePhoto />
      <ChangeName />
      <ChangeEmail />
      <ChangePassword />
    </ThemeProvider>
  );
}