import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { firebasePasswordReset } from '../services';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component='h1' variant='h5'>
          Forgot Password
        </Typography>
        <Box
          component='form'
          onSubmit={(e: any) => e.preventDefault()}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            autoFocus
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            onChange={e => setEmail(e.target.value)}
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => firebasePasswordReset(email)}
          >
            Send Password Reset Email
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
