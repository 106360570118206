export function getErrorMessage(msg: string): string {
  try {
    if (!msg) return '';
    let resultMessage = '';

    switch (msg) {
      case 'auth/user-not-found':
        resultMessage = 'Email is not registered yet.';
        break;
      case 'auth/wrong-password':
        resultMessage = 'Invalid Password.';
        break;
      case 'auth/email-already-in-use':
        resultMessage = 'Email is already existed.';
        break;
      case 'auth/requires-recent-login':
        resultMessage =
          'Your sessing is too old. To do this action, please sign out and sign in again.';
        break;
      default:
        resultMessage = 'Firebase system error. Please try again';
        break;
    }

    return resultMessage;
  } catch (error: any) {
    console.error('[ERROR] getErrorMessage');
    return '';
  }
}
