import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { firebaseAuth } from '../../config/firebase';
import rabbit1 from '../../images/avatar_half_body_rabbit-01.jpeg';
import rabbit2 from '../../images/avatar_half_body_rabbit-02.jpeg';
import rabbit3 from '../../images/avatar_half_body_rabbit-03.jpeg';
import {
  systemSelector,
  setLogOut,
  setNotiData,
  setEmailFormDisplay,
  setPasswordFormDisplay
} from '../../redux/system.slice';
import { firebaseSignOut } from '../../services';

export default function ProfileMenu() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [user, loading, error] = useAuthState(firebaseAuth);
  const { isAuth, isAdminUser, firebaseUser } = useSelector(systemSelector);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [photo, setPhoto] = useState<any>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMenuClick = (path: string) => {
    setAnchorElUser(null);
    navigate(path);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    try {
      firebaseSignOut();
      handleCloseUserMenu();
      dispatch(setLogOut());
      dispatch(setNotiData({ message: 'Sign out successful!', severity: 'success' }));
      navigate('/');
    } catch (error) {
      console.error('[ERROR] handleLogout');
    }
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser.photoURL) {
      switch (firebaseUser.photoURL) {
        case 'rabbit1':
          setPhoto(rabbit1);
          break;
        case 'rabbit2':
          setPhoto(rabbit2);
          break;
        case 'rabbit3':
          setPhoto(rabbit3);
          break;
        default:
          break;
      }
    }
  }, [firebaseUser]);

  if (!isAuth) {
    return null;
  }

  return (
    <Box
      id='user-setting'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}
    >
      <Tooltip title='Open settings'>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt='Tatthar Avatar' src={photo} />
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-appbar'
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={'user-profile-item'}
          onClick={() => {
            handleMenuClick('/profile');
          }}
        >
          <Typography textAlign='center'>Go to Profile</Typography>
        </MenuItem>
        {!!isAdminUser && (
          <MenuItem
            key={'a-d-item'}
            onClick={() => {
              handleMenuClick('/admindashboard');
            }}
          >
            <Typography textAlign='center'>Go to Admin Dashboard</Typography>
          </MenuItem>
        )}
        <Divider />
        {/* <MenuItem
          key={'edit-p-item'}
          onClick={() => {
            setAnchorElUser(null);
            dispatch(setEmailFormDisplay(true));
          }}
        >
          <Typography textAlign='center'>Change Email</Typography>
        </MenuItem> */}
        <MenuItem
          key={'ch-pwd-item'}
          onClick={() => {
            setAnchorElUser(null);
            dispatch(setPasswordFormDisplay(true));
          }}
        >
          <Typography textAlign='center'>Change Password</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key={'logout-item'} onClick={() => handleLogout()}>
          <Typography textAlign='center'>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
