import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';

import {
  systemSelector,
  setpageTitle,
  setCurrentUrl
  // setLoading
} from '../redux/system.slice';

export default function UserDashboard() {
  const dispatch = useDispatch();

  const {
    isAuth,
    // isLoading,
    currentUrl,
    pageTitle
  } = useSelector(systemSelector);

  useEffect(() => {
    dispatch(setCurrentUrl(window.location.href));
    dispatch(setpageTitle('Dashboard'));
  }, [dispatch]);

  return (
    <Grid
      container
      spacing='12'
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      justifyItems={'flex-start'}
      sx={{ marginTop: '12px' }}
    >
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240
          }}
        >
          Popular Rewards
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240
          }}
        >
          Top 5 Rank
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          Redemptions
        </Paper>
      </Grid> */}
    </Grid>
  );
}
