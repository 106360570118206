import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { firebaseAuth } from '../config/firebase';
import { firebaseUpdateNameAndPicture } from '../services';
import {
  systemSelector,
  setNotiData,
  checkUserSession,
  setNameFormDisplay
} from '../redux/system.slice';

export default function ChangeName() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, firebaseUser, isNameFormOpen } = useSelector(systemSelector);

  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (name === '') {
        setErrorMessage("Name can't be empty");
        return;
      }

      const serviceRes: any = await firebaseUpdateNameAndPicture({ displayName: name });

      if (serviceRes.isSucceeded) {
        dispatch(setNameFormDisplay(false));
        dispatch<any>(checkUserSession());
        dispatch(setNotiData({ message: 'Update successful!', severity: 'success' }));
      } else {
        dispatch(
          setNotiData({
            message: serviceRes.message,
            severity: 'error'
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser.displayName !== '') {
      setName(firebaseUser.displayName);
    }
  }, [firebaseUser]);

  return (
    <Grid item xs={12} sx={{ marginTop: '12px' }}>
      <Dialog
        open={isNameFormOpen}
        onClose={() => {
          dispatch(setNameFormDisplay(false));
        }}
        maxWidth={'lg'}
      >
        <DialogTitle>Change Name</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
          <TextField
            autoComplete='given-name'
            name='name'
            required
            fullWidth
            id='name'
            label='Name'
            autoFocus
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setNameFormDisplay(false));
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
