import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, CardActionArea, CardActions } from '@mui/material';

import AppCard from '../AppCard';
import RewardCard from '../RewardCard';

interface IUserRewardsProps {
  rewardList: any;
}

export default function UserRewards(props: IUserRewardsProps) {
  const rewardItems = props.rewardList;

  return (
    <Box
      sx={{
        width: '100%',
        padding: '0px 0px 24px 0px',
        margin: '0px 0px 12px 0px'
      }}
    >
      <Typography
        sx={{ margin: '12px 0px 8px 0px', fontSize: '16px', fontWeight: 'bold' }}
        component='h5'
      >
        Rewards
      </Typography>

      <Grid container spacing={4}>
        {rewardItems.map((item: any, index: number) => {
          return (
            <Grid item key={`item-${index}`} xs={12} sm={12} md={6} lg={3}>
              <RewardCard cardData={item} key={`card-${index}`} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
