import * as React from 'react';
import { Outlet, Link as RouterLink, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useMediaQuery from '@mui/material/useMediaQuery';
import SidebarMenu from '../../components/SidebarMenu/SidebarMenu';
import Header from '../../components/Header';
import MainTheme from '../../mainTheme';
import { firebaseAuth } from '../../config/firebase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  systemSelector,
  setpageTitle,
  setCurrentUrl
  // setLoading
} from '../../redux/system.slice';

export default function AccountOpenBanner() {
  const {
    isAuth
    // isLoading
  } = useSelector(systemSelector);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    if (!event || !event.currentTarget.contains(event.relatedTarget)) {
      setAnchorEl(null);
    }
  };
  

  if (isAuth) {
    return null;
  }

  if (isMobile) {
    return (
      <>
            <RouterLink
              to='/signin'
              style={{
                textDecoration: 'none',
                marginLeft: '12px'
              }}
            >
              <Button variant='contained' size='small'>
                Sign In
              </Button>
            </RouterLink>
      </>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px',
        margin: '8px 0px 8px 0px'
      }}
    >
      <RouterLink
        to='/signup'
        style={{
          textDecoration: 'none',
          marginLeft: '12px'
        }}
      >
        <Button variant='outlined' size='small'>
          Create Tatthar Account
        </Button>
      </RouterLink>
      <RouterLink
        to='/signin'
        style={{
          textDecoration: 'none',
          marginLeft: '12px'
        }}
      >
        <Button variant='contained' size='small'>
          Sign In
        </Button>
      </RouterLink>
    </Box>
  );
}
