import React from 'react';
import {
  // useLocation,
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import AdbIcon from '@mui/icons-material/Adb';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function LogoBox() {
  return (
    <Box
      id='app-title'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <RouterLink
        to='/'
        style={{
          textDecoration: 'none',
          color: '#000'
        }}
      >
        <AdbIcon sx={{ display: { xs: 'flex', md: 'flex' }, color: '#8e0000', mr: 1 }} />
        <Typography
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.1rem',
            color: '#8e0000',
            textDecoration: 'none'
          }}
        >
          Online Tatthar
        </Typography>
      </RouterLink>
    </Box>
  );
}
