import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RedeemIcon from '@mui/icons-material/Redeem';
import ScoreIcon from '@mui/icons-material/Score';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import { firebaseSignOut } from '../../services';

export default function SidebarMenu() {
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List component='nav'>
      <RouterLink
        to={'/admindashboard'}
        style={{
          color: '#000',
          textDecoration: 'none'
        }}
        // onClick={(event) => handleListItemClick(event)}
      >
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={event => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItemButton>
      </RouterLink>
      <RouterLink
        to={'/users'}
        style={{
          color: '#000',
          textDecoration: 'none'
        }}
        // onClick={(event) => handleListItemClick(event)}
      >
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={event => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary='Users' />
        </ListItemButton>
      </RouterLink>

      {/* <Divider sx={{ my: 1 }} />
      <ListSubheader component='div' inset>
        User Info
      </ListSubheader>
      <RouterLink
        to={'/profile'}
        style={{
          color: '#000',
          textDecoration: 'none'
        }}
        // onClick={(event) => handleListItemClick(event)}
      >
        <ListItemButton>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary='Profile' />
        </ListItemButton>
      </RouterLink> */}
    </List>
  );
}
