import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { ref, getDownloadURL } from 'firebase/storage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';

import { firebaseAuth, firebaseStorage } from '../../config/firebase';

interface IAppCard {
  id: string;
  name: string;
  description: string;
  logo: string;
  earnedPoints: number;
}

export default function AppCard(props: any) {
  const appData = props.appDetails;

  console.log(appData)

  const app: IAppCard = {
    id: appData._id,
    name: appData._id.name || '',
    logo: appData._id.logo || '',
    description: appData._id.description || '',
    earnedPoints: appData.point
  };

  useEffect(() => {
    if (app.logo !== '') {
      getDownloadURL(ref(firebaseStorage, app.logo)).then(url => {
        const img = document.getElementById(`logo-${app.id}`);
        if (img) img.setAttribute('src', url);
      });
    }
  }, [app.id, app.logo]);

  if (_.isEmpty(appData)) {
    return null;
  }

  return (
    <Card
      sx={{
        // maxWidth: 345
        // margin: '4px'
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      {/* <CardActionArea> */}
      <CardMedia id={`logo-${app.id}`} component='img' height='140' alt={app.name} />
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <Typography gutterBottom variant='body1'>
          {app.name}
        </Typography>
        <Typography gutterBottom variant='body2'>
          <strong>{app.earnedPoints}</strong> Earned Points
        </Typography>
        {/* <Typography variant='body2' color='text.secondary'>
          {app.description}
        </Typography> */}
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions>
        <RouterLink
          to={`/approvedapps/${app.id}`}
          style={{
            textDecoration: 'none',
            color: '#000'
          }}
        >
          <Button size='small' color='primary'>
            View Details
          </Button>
        </RouterLink>
      </CardActions>
    </Card>
  );
}
