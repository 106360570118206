import axios from 'axios';

import store from '../redux/store';
import { firebaseAuth } from '../config/firebase';
import { OTT_SERVER } from '../constants';
import { IFirebaseUser, IResponse } from '../interfaces';
import {
  setOttUser,
  setAuthSuccess,
  setFirebaseUser,
  setAdminAuthSuccess
} from '../redux/system.slice';
import {
  firebaseCreateUser,
  firebaseDeleteUser,
  firebaseSignIn,
  firebaseSignOut
} from './firebase.service';

export const ottCreateUser = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };
  try {
    let firebaseIdToken = '';
    if (firebaseAuth.currentUser) {
      firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.post(
      `${OTT_SERVER}/user`,
      { payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${firebaseIdToken}`,
          'AppID': firebaseAppCheckToken,
          'X-Firebase-AppCheck': firebaseAppCheckToken
        }
      }
    );
    resData.isSucceeded = true;
    resData.itemDetails = res.data;

    return resData;
  } catch (error: any) {
    console.error('[ERROR]: ottCreateUser', JSON.stringify(error));
    resData.message = 'Something went wrong in Create user. Please try again.';
    resData.error = error;
    return resData;
  }
};

// export const getUser = async (axiosCancelToken: any): Promise<any> => {
//   const resData: IResponse = {
//     isSucceeded: false,
//     message: '',
//     error: {},
//     itemDetails: {}
//   };
//   try {
//     let firebaseIdToken = '';
//     if (firebaseAuth.currentUser) {
//       firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
//     }

//     let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
//     // const appCheckRes = await getToken(firebaseAppCheck, false);
//     // firebaseAppCheckToken = appCheckRes.token;

//     const res = await axios.get(`${OTT_SERVER}/user`, {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `JWT ${firebaseIdToken}`,
//         'AppID': firebaseAppCheckToken
//       },
//       cancelToken: axiosCancelToken
//     });

//     if (!res.data) {
//       resData.message = 'No User Data.';
//       return resData;
//     }

//     resData.isSucceeded = true;
//     resData.itemDetails = res.data;

//     return resData;
//   } catch (error: any) {
//     if (axios.isCancel(error)) {
//       console.log('[INFO]: getUser aborted successfully!');
//     } else {
//       console.error('[ERROR] getUser:', error);
//     }
//     resData.message = 'Something went wrong. Please try again.';
//     resData.error = error;
//     // if (error.response) {
//     //   if (error.response.status === 500) {
//     //     resData.message = error.response.data;
//     //   } else {
//     //     switch (error.response.data) {
//     //       case 'INVALID_EMAIL':
//     //         resData.message = 'Invalid email or email not found.';
//     //         break;
//     //       case 'INVALID_PASSWORD':
//     //         resData.message = 'Incorrect password.';
//     //         break;
//     //       default:
//     //         resData.message = 'Something went wrong. Please try again.';
//     //         break;
//     //     }
//     //   }
//     // } else {
//     //   resData.message = 'Something went wrong. Please try again.';
//     //   resData.error = error;
//     // }
//     return resData;
//   }
// };

export const getUserWithoutAxiosCancel = async (
  firebaseIdToken: string
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };
  try {
    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/user`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      }
    });

    if (!res.data) {
      resData.message = 'No User Data.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemDetails = res.data;

    return resData;
  } catch (error: any) {
    console.error('[ERROR] getUserWithoutAxiosCancel:', error);
    resData.message = 'Something went wrong. Please try again.';
    resData.error = error;
    return resData;
  }
};

export const signUpUser = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    messageType: '',
    error: {},
    itemDetails: {}
  };
  try {
    const firebaseUser: any = await firebaseCreateUser(
      payload.name,
      payload.email,
      payload.password,
      payload.imageUrl
    );

    if (!firebaseUser.isSucceeded) {
      resData.message = firebaseUser.message;
      resData.messageType = 'FORM';
      return resData;
    }

    const ottUser: any = await ottCreateUser(payload);

    if (!ottUser.isSucceeded) {
      await firebaseDeleteUser();
      console.error('[ERROR] Failed to add User in server.');

      resData.message = ottUser.message;
      resData.messageType = 'FORM';
      return resData;
    }

    store.dispatch<any>(setAuthSuccess());

    if (firebaseAuth && firebaseAuth.currentUser) {
      firebaseAuth.currentUser
        .getIdTokenResult()
        .then(idTokenResult => {
          if (!!idTokenResult.claims.admin) {
            // console.log(' admin');
            store.dispatch<any>(setAdminAuthSuccess());
          }
        })
        .catch(error => {
          console.error('[ERROR] getIdTokenResult', error);
        });
    }

    const token = firebaseUser.itemDetails.accessToken;
    localStorage.setItem('firebaseAccessToken', token);

    const fUserData: IFirebaseUser = {
      uid: firebaseUser.itemDetails.uid,
      email: firebaseUser.itemDetails.email,
      emailVerified: firebaseUser.itemDetails.emailVerified,
      displayName: firebaseUser.itemDetails.displayName,
      photoURL: firebaseUser.itemDetails.photoURL
    };
    store.dispatch<any>(setFirebaseUser(fUserData));

    store.dispatch<any>(setOttUser(ottUser.itemDetails));

    resData.isSucceeded = true;
    resData.itemDetails = ottUser.itemDetails;
    resData.message = 'Sign Up successful.';
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: signUpUser', JSON.stringify(error));
    resData.message = 'Something went wrong User Sign Up. Please try again.';
    resData.error = error;
    return resData;
  }
};

export const signInUser = async (payload: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    messageType: '',
    error: {},
    itemDetails: {}
  };

  try {
    const firebaseUser: any = await firebaseSignIn(payload.email, payload.password);
    if (!firebaseUser.isSucceeded) {
      resData.message = firebaseUser.message;
      resData.messageType = 'FORM';
      return resData;
    }

    store.dispatch<any>(setAuthSuccess());

    if (firebaseAuth && firebaseAuth.currentUser) {
      firebaseAuth.currentUser
        .getIdTokenResult()
        .then(idTokenResult => {
          if (!!idTokenResult.claims.admin) {
            // console.log(' admin');
            store.dispatch<any>(setAdminAuthSuccess());
          }
        })
        .catch(error => {
          console.error('[ERROR] getIdTokenResult', error);
        });
    }

    const token = firebaseUser.itemDetails.accessToken;
    localStorage.setItem('firebaseAccessToken', token);

    const fUserData: IFirebaseUser = {
      uid: firebaseUser.itemDetails.uid,
      email: firebaseUser.itemDetails.email,
      emailVerified: firebaseUser.itemDetails.emailVerified,
      displayName: firebaseUser.itemDetails.displayName,
      photoURL: firebaseUser.itemDetails.photoURL
    };
    store.dispatch<any>(setFirebaseUser(fUserData));

    const ottUser = await getUserWithoutAxiosCancel(token);
    if (ottUser.isSucceeded) {
      store.dispatch<any>(setOttUser(ottUser.itemDetails));
    }
    
    resData.isSucceeded = true;
    resData.message = 'Sign in successful!';
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: signin', error);
    resData.message = 'Invalid Email or Password. Please try again.';
    resData.error = error;
    return resData;
  }
};

export const getUsers = async (axiosCancelToken: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseIdToken = localStorage.getItem('firebaseIdToken') || '';
    // if (firebaseAuth.currentUser) {
    //   firebaseIdToken = await firebaseAuth.currentUser.getIdToken(false);
    // }

    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/apps`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${firebaseIdToken}`,
        'AppID': firebaseAppCheckToken,
        'X-Firebase-AppCheck': firebaseAppCheckToken
      },
      cancelToken: axiosCancelToken
    });

    if (!res.data || !res.data.length) {
      resData.message = 'No Apps Data.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getApps aborted successfully!');
    } else {
      console.error('[ERROR] getApps:', error);
    }
    resData.error = error;
    return resData;
  }
};
