import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { setpageTitle } from '../redux/system.slice';

export default function ScorePoints() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setpageTitle('Score Points'));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 240
        }}
      >
        This is ScorePoints Page.
      </Paper>
    </Grid>
  );
}
