import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { ref, getDownloadURL } from 'firebase/storage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';

import { firebaseAuth, firebaseStorage } from '../../config/firebase';

export default function RewardCard(props: any) {
  const cardDetails = props.cardData;

  const details: any = {
    id: cardDetails._id._id,
    name: cardDetails._id.name || '',
    logo: cardDetails._id.logo || '',
    code: cardDetails._id.code || '',
    point: cardDetails._id.point || '',
    validUntil: cardDetails._id.validUntil || '',
    quantity: cardDetails.quantity
  };

  useEffect(() => {
    if (details.logo !== '') {
      getDownloadURL(ref(firebaseStorage, details.logo)).then(url => {
        const img = document.getElementById(`logo-${details.id}`);
        if (img) img.setAttribute('src', url);
      });
    }
  }, [details.id, details.logo]);

  if (_.isEmpty(cardDetails)) {
    return null;
  }

  return (
    <Card
      sx={{
        // maxWidth: 345
        // margin: '4px'
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      {/* <CardActionArea> */}
      <CardMedia
        id={`logo-${details.id}`}
        component='img'
        height='140'
        alt={details.name}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <Typography gutterBottom variant='body1'>
          {details.name}
        </Typography>
        <Typography gutterBottom variant='body2'>
          <strong>{details.quantity}</strong>
        </Typography>
        {/* <Typography variant='body2' color='text.secondary'>
          {details.description}
        </Typography> */}
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions>
        {/* <RouterLink
          to={`/approvedapps/${details.id}`}
          style={{
            textDecoration: 'none',
            color: '#000'
          }}
        >
          <Button size='small' color='primary'>
            View Details
          </Button>
        </RouterLink> */}
      </CardActions>
    </Card>
  );
}
