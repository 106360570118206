import axios from 'axios';
// import { getToken  } from "firebase/app-check";

import { OTT_SERVER } from '../constants';
import { IResponse } from '../interfaces';

export const getRegions = async (): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;
    const res = await axios.get(`${OTT_SERVER}/regions`, {
      headers: {
        'Content-Type': 'application/json',
        'AppID': firebaseAppCheckToken
      }
    });

    if (!res.data.regions || !res.data.regions.length) {
      resData.message = 'No Regions Data.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = res.data.regions;

    return resData;
  } catch (error: any) {
    console.error('[ERROR] getRegions:', error);
    resData.error = error;
    return resData;
  }
};
