import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import MouseIcon from '@mui/icons-material/Mouse';

import AccountOpenBanner from '../components/AccountOpenBanner';

export default function LandingPage() {
  return (
    <Box
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      justifyItems={'flex-start'}
      width='100%'
      sx={{
        marginTop: 10
      }}
    >
      {/* <AccountOpenBanner /> */}

      <Typography
        display='flex'
        alignItems='center'
        component='h1'
        variant='h4'
        align='center'
        color='#fff'
        height='100px'
        justifyContent='center'
        sx={{
          background: '#c62828'
        }}
        gutterBottom
      >
        Online Tatthar
      </Typography>

      <Box
        padding='1.5vh'
        display='flex'
        gap='3vh'
        width='100%'
        sx={{ flexDirection: { xs: 'column', lg: 'row' }, marginBottom: { lg: '15px' } }}
      >
        <div style={{ flex: 1, width: '100%' }}>
          <img
            src='https://i.redd.it/aejd5qipuhu61.jpg'
            alt=''
            style={{ flex: 1, width: '100%' }}
          />
        </div>
        <Typography
          sx={{
            flex: 1,
            paddingTop: '15px'
          }}
        >
          လူတချို့ တော်လှန်ရေးမှာ အသက်ပေးပြီးတိုက်နေကြတယ်။ <br /> လူတချို့
          ရှာဖွေထားရတဲ့ချွေးနဲစာငွေကြေးနဲ့ထောက်ပို့နေကြတယ်။ <br /> လူတချို့ CDM ပြီး
          အာဏာဖီဆန်မှုကိုပြကြတယ်။
          <br />
          <br />
          အကြောင်းကြောင်းကြောင့် အသက်လည်း မပေးနိုင်၊ ငွေကြေးလည်း မပါနိုင်၊ အလုပ်ကိုလည်း
          မစွန့်နိုင်ကြသူတွေအတွက် စကစကိုတိုက်ဖို့ အသင့်တော်ဆုံးနည်းလမ်းကတော့ တစ်နေ့ကို
          အချိန် အနည်းငယ်ပေးပြီး{' '}
          <b style={{ color: '#c62828', fontSize: '18px' }}>click</b> ကြဖို့ပါပဲ။
          <br />
          <br />
        </Typography>
      </Box>

      <Box m='60px 0px'>
        <Typography
          sx={{
            backgroundColor: '#ff5f52',
            fontSize: 18,
            display: 'block',
            width: { sm: '100%', md: '70%' },
            color: 'white',
            padding: '10px',
            fontWeight: 700
          }}
        >
          Click ဖို့အတွက် အသက်စွန့်စရာမလိုပါ။
        </Typography>
        <ul style={{ listStyle: 'none' }}>
          <li
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '5px',
              marginBottom: '5px'
            }}
          >
            <MouseIcon sx={{ color: '#c62828' }} />
            <Typography>
              {' '}
              <b style={{ color: '#c62828', fontSize: '18px' }}>Click</b> ဖို့အတွက်
              ငွေကြေးအများကြီးထည့်ဝင်စရာမလိုပါ။
            </Typography>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '5px',
              marginBottom: '5px'
            }}
          >
            <MouseIcon sx={{ color: '#c62828' }} />
            <Typography>
              <b style={{ color: '#c62828', fontSize: '18px' }}>Click</b> တဲ့အတွက်
              စကစရဲ့ထောင်ချောက်မိစရာမရှိ။ မိမိလုံခြုံရေး လုံးဝ လုံး၀စိတ်ပူစရာမလို။
            </Typography>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '5px',
              marginBottom: '5px'
            }}
          >
            <MouseIcon sx={{ color: '#c62828' }} />
            <Typography>
              <b style={{ color: '#c62828', fontSize: '18px' }}>Click</b> တချက်ဟာ စကစကို
              ဖြိုခွင်းဖို့အတွက် ကျည်တချက်ပါပဲ။
            </Typography>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '5px',
              marginBottom: '5px'
            }}
          >
            <MouseIcon sx={{ color: '#c62828' }} />
            <Typography>
              <b style={{ color: '#c62828', fontSize: '18px' }}>Click</b> လိုက်တဲ့
              မိနစ်တိုင်းဟာ တော်လှန်ရေးအတွက် ငွေကြေးထောက်ပို့ပါပဲ။
            </Typography>
          </li>
        </ul>
        ထို့အတွက်ကြောင့် clicker တွေကို
        <b style={{ color: '#c62828', fontSize: '18px' }}>“အွန်လိုင်းတပ်သား”</b> လို့
        ထိုက်ထိုက်တန်တန် ခေါ်ပါရစေ။
      </Box>

      <Box m='60px 0px'>
        <Typography
          sx={{
            fontSize: '1.6rem',
            display: 'block',
            width: '100%',
            color: '#c62828',
            padding: '10px',
            fontWeight: 700,
            textAlign: 'center',
            marginBottom: '2rem'
          }}
        >
          အွန်လိုင်းတပ်သားဖြစ်ဖို့ ဘာတွေလိုမလဲ?
        </Typography>
        <Box
          display='flex'
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '2rem', md: '0.5rem' },
            alignItems: { xs: 'center', md: 'flex-start' }
          }}
        >
          <Box
            flex='1'
            alignItems='center'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            gap='2rem'
            sx={{
              '&:hover': {
                transform: 'scale(1.05)'
              },
              transition: 'all 0.5s ease-out'
            }}
          >
            <Typography
              sx={{
                borderRadius: '50%',
                backgroundColor: '#c62828',
                width: '140px',
                height: '140px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: '700',
                fontSize: '4rem'
              }}
            >
              ၁
            </Typography>
            <Typography textAlign='center' p='0px 15px'>
              <Link color='#c62828' style={{ cursor: 'pointer' }}>
                www.onlinetatthar.com
              </Link>{' '}
              တွင် စာရင်းသွင်း (Register) လုပ်ရန်။
            </Typography>
          </Box>
          <Box
            flex='1'
            alignItems='center'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            gap='2rem'
            sx={{
              '&:hover': {
                transform: 'scale(1.05)'
              },
              transition: 'all 0.5s ease-out'
            }}
          >
            <Typography
              sx={{
                borderRadius: '50%',
                backgroundColor: '#c62828',
                width: '140px',
                height: '140px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: '700',
                fontSize: '4rem',
                paddingBottom: '2rem'
              }}
            >
              ၂
            </Typography>
            <Typography textAlign='center' p=' 0px 15px'>
              Online Tatthar Trainer များမှ ပို့ချသော
              <br />
              Online Training Video များ၊
              <br />
              အခါအားလျော်စွာကျင်းပသော Virtual Training များအားတက်ရောက်ရန်။
            </Typography>
          </Box>
          <Box
            flex='1'
            alignItems='center'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            gap='2rem'
            sx={{
              '&:hover': {
                transform: 'scale(1.05)'
              },
              transition: 'all 0.5s ease-out'
            }}
          >
            <Typography
              sx={{
                borderRadius: '50%',
                backgroundColor: '#c62828',
                width: '140px',
                height: '140px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: '700',
                fontSize: '4rem',
                paddingBottom: '2rem'
              }}
            >
              ၃
            </Typography>
            <Typography textAlign='center' p='0px 15px'>
              တတ်မြောက်မှုအား Self Assessment စနစ်ဖြင့် စစ်ဆေး၍ အောင်မြင်ပါက
              အွန်လိုင်းတပ်သားတာဝန်အား အပြည့်အဝထမ်းဆောင်နိုင်ပါသည်။
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        m='60px 0px'
        display='flex'
        sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: '2rem' }}
      >
        <Box
          sx={{
            flex: '1',
            padding: '24px 18px',
            width: '100%',
            height: '310px',
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '20px',
            boxShadow:
              '0px 19px 50px 0px rgba(197, 192, 192, 0.5), inset 0px 0px 10px 0px rgba(197, 192, 192, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255)',
            '&:hover': {
              transform: 'scale(1.05)',
              cursor: 'default'
            },
            transition: 'all 0.5s ease-out'
          }}
        >
          <Typography
            sx={{
              backgroundColor: '#ff5f52',
              fontSize: 18,
              display: 'block',
              width: '100%',
              borderRadius: '20px',
              color: 'white',
              padding: '10px',
              fontWeight: 700,
              marginBottom: '2rem'
            }}
          >
            အွန်လိုင်းတပ်သားဖြစ်ရင် ဘာတွေလုပ်ရမလဲ?
          </Typography>
          <Typography p='5px 10px'>
            အွန်လိုင်းတပ်သားများ ထမ်းဆောင်ရန်တာဝန်များအား ဌာနချုပ်မှ ချမှတ်ပြီး
            အချိန်နှင့် တပြေးညီ တပ်သားများထံ အကြောင်းကြားသွားမည်။
            အွန်လိုင်းတပ်သားများအနေဖြင့် ဌာနချုပ်မှ ချမှတ်လာတဲ့ တာ၀န်များအား နေ့စဥ်
            မပျက်မကွက် ထမ်းဆောင်ပြီး တိုက်ပွဲ၀င်သွားရမည်ဖြစ်သည်။
          </Typography>
        </Box>

        <Box
          sx={{
            flex: '1',
            padding: '24px 18px',
            width: '100%',
            height: '310px',
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '20px',
            boxShadow:
              '0px 19px 50px 0px rgba(197, 192, 192, 0.5), inset 0px 0px 10px 0px rgba(197, 192, 192, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255)',
            '&:hover': {
              transform: 'scale(1.05)',
              cursor: 'default'
            },
            transition: 'all 0.5s ease-out'
          }}
        >
          <Typography
            sx={{
              backgroundColor: '#ff5f52',
              fontSize: 18,
              display: 'block',
              width: '100%',
              borderRadius: '20px',
              color: 'white',
              padding: '10px',
              fontWeight: 700,
              marginBottom: '2rem'
            }}
          >
            အွန်လိုင်းတပ်သားဖြစ်ရင် ဘာတွေရမှာလဲ?
          </Typography>
          <Typography p='5px 10px'>
            အွန်လိုင်းတပ်သားများအနေဖြင့် တာဝန်ထမ်း‌ဆောင်မှုအလိုက် ရရှိလာသည့်
            ရမှတ်များအပေါ် မူတည်၍ ရာထူးများ သတ်မှတ်ခြင်း၊ အကျိုးခံစားခွင့်များ
            ရရှိမည်ဖြစ်သည်။ နောက်ဆုံး အဖိုးထိုက်တန်ဆုံး ရလဒ်ကတော့ အားလုံးဆန္ဒရှိတဲ့
            “လွတ်လပ်သော တရားမျှတတဲ့ ဒီမိုကရေစီ” ပဲဖြစ်ပါသည်။
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}