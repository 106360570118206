import moment from 'moment-timezone';

export function getTimestamptString(date: string | Date, dateFormat?: string): string {
  try {
    if (!date) return '';

    const timestampt = moment.tz(date, 'Asia/Singapore');

    if (!dateFormat) return timestampt.format();

    return timestampt.format(dateFormat);
  } catch (error) {
    console.error('[ERROR] getTimestamptString');
    return '';
  }
}

export function isExpiredTimestampt(dateTime: string): boolean {
  try {
    if (!dateTime) return false;
    const todayDate = new Date();
    const currentTime = moment.tz(todayDate, 'Asia/Singapore').format();

    const expiryTime = moment.tz(dateTime, 'Asia/Singapore').format();

    const isExp = moment(currentTime).isBefore(expiryTime);

    return isExp;
  } catch (error) {
    console.error('[ERROR] isExpiredTimestampt');
    return false;
  }
}

// REF: https://stackoverflow.com/a/50398144/3269542
export const getDaysBetweenDates = (start: string, end: string) => {
  try {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(getTimestamptString(dt, 'YYYY-MM-DD'));
    }
    return arr;
  } catch (error) {
    console.error('[ERROR] getDaysBetweenDates');
  }
};
