import React, { useEffect, useState } from 'react';
import { Outlet, Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from 'firebase/auth';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

import { firebaseAuth } from '../config/firebase';

import {
  systemSelector,
  setpageTitle,
  setCurrentUrl
  // setLoading
} from '../redux/system.slice';

export type ProtectedAdminRouteProps = {
  outlet: JSX.Element;
};

export default function ProtectedAdminRoute({ outlet }: ProtectedAdminRouteProps) {
  let navigate = useNavigate();
  const { isAuth, isAdminUser } = useSelector(systemSelector);
  // const [user, loading, error] = useAuthState(firebaseAuth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthUser = onAuthStateChanged(firebaseAuth, (user: any) => {
      if (!user) {
        navigate('/accessrequired');
      }

      user
        .getIdTokenResult()
        .then((idTokenResult: any) => {
          if (!idTokenResult.claims.admin) {
            navigate('/accessrequired');
          }
        })
        .catch((error: any) => {
          console.error('[ERROR] getIdTokenResult', error);
        });
      setIsLoading(false);
    });

    return () => checkAuthUser();
  }, [navigate]);

  if (isLoading) {
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={true}
          // onClick={handleClose}
        >
          <Typography variant='h6' align='center' color='text.primary'>
            Checking session
          </Typography>
          <span className='dot-flashing'></span>
        </Backdrop>
      </>
    );
  }

  return outlet;
}
