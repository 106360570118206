import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { firebaseAuth } from '../config/firebase';
import { firebaseUpdateNameAndPicture } from '../services';
import {
  systemSelector,
  setNotiData,
  setPhotoFormDisplay,
  checkUserSession,
  setNameFormDisplay,
  setEmailFormDisplay
} from '../redux/system.slice';

import kitten1 from '../images/kitten1.png';
import kitten2 from '../images/kitten2.png';
import rabbit1 from '../images/avatar_half_body_rabbit-01.jpeg';
import rabbit2 from '../images/avatar_half_body_rabbit-02.jpeg';
import rabbit3 from '../images/avatar_half_body_rabbit-03.jpeg';

export default function ChangeProfilePhoto() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, firebaseUser, isPhotoFormOpen } = useSelector(systemSelector);

  const [imageUrl, setImageUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (imageUrl === '') {
        setErrorMessage('Select a photo.');
        return;
      }
      const serviceRes: any = await firebaseUpdateNameAndPicture({ photoURL: imageUrl });

      if (serviceRes.isSucceeded) {
        dispatch(setPhotoFormDisplay(false));
        dispatch<any>(checkUserSession());
        dispatch(setNotiData({ message: 'Update successful!', severity: 'success' }));
      } else {
        dispatch(
          setNotiData({
            message: serviceRes.message,
            severity: 'error'
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser.photoURL !== '') {
      setImageUrl(firebaseUser.photoURL);
    }
  }, [firebaseUser]);

  return (
    <Grid item xs={12} sx={{ marginTop: '12px' }}>
      <Dialog
        open={isPhotoFormOpen}
        onClose={() => {
          dispatch(setPhotoFormDisplay(false));
        }}
        maxWidth={'lg'}
      >
        <DialogTitle>Change Profile Photo</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
          <InputLabel id='profile-photo-label'>Select Profile Photo</InputLabel>

          <img
            height='80'
            width='80'
            src={rabbit1}
            alt='Profile'
            onClick={() => {
              setImageUrl('rabbit1');
            }}
            style={{
              margin: '0px ',
              border: imageUrl === 'rabbit1' ? '3px solid #c62828' : '3px solid #fff',
              borderRadius: '12px'
            }}
          />
          <img
            height='80'
            width='80'
            src={rabbit2}
            alt='Profile'
            onClick={() => {
              setImageUrl('rabbit2');
            }}
            style={{
              margin: '0px 0px 0px 8px',
              border: imageUrl === 'rabbit2' ? '3px solid #c62828' : '3px solid #fff',
              borderRadius: '12px'
            }}
          />
          <img
            height='80'
            width='80'
            src={rabbit3}
            alt='Profile'
            onClick={() => {
              setImageUrl('rabbit3');
            }}
            style={{
              margin: '0px 0px 0px 8px',
              border: imageUrl === 'rabbit3' ? '3px solid #c62828' : '3px solid #fff',
              borderRadius: '12px'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setPhotoFormDisplay(false));
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
