import axios from 'axios';
// import { getToken  } from "firebase/app-check";

import { firebaseAuth, firebaseAppCheck } from '../config/firebase';
import { OTT_SERVER } from '../constants';
import { IResponse } from '../interfaces';

export const getApps = async (axiosCancelToken: any): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemList: []
  };

  try {
    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;

    const res = await axios.get(`${OTT_SERVER}/apps`, {
      headers: {
        'Content-Type': 'application/json',
        AppID: firebaseAppCheckToken
      }
    });

    const items = res.data.apps;

    if (!items || !items.length) {
      resData.message = 'No Apps Data.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemList = items;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getApps aborted successfully!');
    } else {
      console.error('[ERROR] getApps:', error);
    }
    resData.error = error;
    return resData;
  }
};

export const getAppDetails = async (
  axiosCancelToken: any,
  appId: string
): Promise<any> => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: []
  };

  try {
    let firebaseAppCheckToken = localStorage.getItem('firebaseAppCheckToken') || '';
    // const appCheckRes = await getToken(firebaseAppCheck, false);
    // firebaseAppCheckToken = appCheckRes.token;
    const res = await axios.get(`${OTT_SERVER}/apps/${appId}`, {
      headers: {
        'Content-Type': 'application/json',
        AppID: firebaseAppCheckToken
      }
    });

    if (!res.data) {
      resData.message = 'No App Data.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemDetails = res.data;

    return resData;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('[INFO]: getAppDetails aborted successfully!');
    } else {
      console.error('[ERROR] getAppDetails:', error);
    }
    resData.error = error;
    return resData;
  }
};
