import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ref, getDownloadURL } from 'firebase/storage';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { visuallyHidden } from '@mui/utils';

import { ENABLE_AXIOS_CANCEL_TOKEN } from '../constants';
import { firebaseAuth, firebaseStorage } from '../config/firebase';
import { setpageTitle } from '../redux/system.slice';
import { getAppDetails } from '../services';

export default function ApprovedAppDetails() {
  const dispatch = useDispatch();
  const { appId } = useParams();
  const aAppId = appId || '';
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [row, setRow] = useState<any>();

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();
    const axiosCancelToken = axiosSource.token;

    const fetchApps = async () => {
      if (aAppId === '') {
        return;
      }
      const serviceRes: any = await getAppDetails(axiosCancelToken, aAppId);
      if (serviceRes.isSucceeded) {
        setRow(serviceRes.itemDetails);
      } else {
        console.log('[INFO] No App Data');
      }
      setIsDataLoaded(true);
    };

    if (!isDataLoaded) fetchApps();

    return () => {
      if (ENABLE_AXIOS_CANCEL_TOKEN === 'TRUE') {
        axiosSource.cancel();
      }
    };
  }, [aAppId, isDataLoaded]);

  useEffect(() => {
    if (row && row.logo) {
      getDownloadURL(ref(firebaseStorage, row.logo)).then(url => {
        const img = document.getElementById(`logo-${row._id}`);
        if (img) img.setAttribute('src', url);
      });
    }
  }, [row]);

  return (
    <Grid
      container
      spacing='12'
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      justifyItems={'flex-start'}
      sx={{ marginTop: '12px' }}
    >
      <Typography
        sx={{
          margin: '12px 0px 12px 0px',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        component='h5'
      >
        App Details
      </Typography>
      {/* <Paper
        sx={{
          p: 2,
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 240
        }}
      >
        {rows.length < 0 && <Typography>No Apps Data yet!</Typography>}
       
      </Paper> */}
      {!_.isEmpty(row) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Paper
            sx={{
              padding: '16px',
              margin: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'flex-start',
              justifyContent: 'flex-start',
              alignItems: 'stretch'
              // borderBottom: '1px solid #ffe1e1',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  minHeight: '160px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img id={`logo-${row._id}`} width='160px' height='160px' alt='app-logo' />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  padding: '0px 0px 0px 12px',
                  margin: '0px',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  alignContent: 'flex-start',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant='subtitle1' sx={{ width: '100%', fontSize: '20px' }}>
                  {row.name}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ flexGrow: 1, mb: '12px' }}
                >
                  asdfs asfd sdf safs dafs dafs dafs dafs dfsda fsd afsd asdfs asfd sdf
                  safs dafs dafs dafs dafs dfsda fsd afsd asdfs asfd sdf safs dafs dafs
                  dafs dafs dfsda fsd afsd
                </Typography>
                <Grid item container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <Button fullWidth variant='outlined'>
                      App Store
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button fullWidth variant='outlined'>
                      Play Store
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {' '}
                    <Button fullWidth variant='outlined'>
                      APK Direct
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </Grid>
  );
}
