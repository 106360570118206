import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  onAuthStateChanged,
  getIdToken,
  getIdTokenResult,
  deleteUser
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from 'firebase/firestore';

import { IResponse } from '../interfaces';
import store from '../redux/store';
import { setFirebaseUser } from '../redux/system.slice';
import {
  firebaseApp,
  firebaseAuth,
  firebaseDB,
  googleProvider
} from '../config/firebase';
import { getErrorMessage } from '../utils/firebase.util';

export const firebaseGoogleSignIn = async () => {
  try {
    const res = await signInWithPopup(firebaseAuth, googleProvider);
    const user = res.user;
    const q = query(collection(firebaseDB, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(firebaseDB, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email
      });
    }
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

export const firebaseSignIn = async (email: string, password: string) => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };

  try {
    const res = await signInWithEmailAndPassword(firebaseAuth, email, password);
    const user: any = res.user;
    if (!user.accessToken || user.accessToken === '') {
      resData.message = 'Firebase Token not found.';
      return resData;
    }

    resData.isSucceeded = true;
    resData.itemDetails = res.user;
    resData.message = 'Sign in successful!';
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: firebaseSignIn', error);
    resData.message = getErrorMessage(error.code);
    return resData;
  }
};

export const firebaseCreateUser = async (
  name: string,
  email: string,
  password: string,
  imageUrl: string
) => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };

  try {
    const res = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    const user: any = res.user;
    if (!user.accessToken || user.accessToken === '') {
      resData.message = 'Firebase Token not found.';
      return resData;
    }

    if (user) {
      await sendEmailVerification(user).catch((err: any) => {
        console.error('[ERROR] sendEmailVerification', err);
        // resData.message = 'Fail to send verification mail.';
        // return resData;
      });

      let firebaseIdToken = await user.getIdToken(false);
      localStorage.setItem('firebaseIdToken', firebaseIdToken);

      updateProfile(user, {
        displayName: name,
        photoURL: imageUrl
      })
        .then(updated => {
          const u: any = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL
          };
          store.dispatch<any>(setFirebaseUser(u));
        })
        .catch((error: any) => {
          console.error('[ERROR] sendEmailVerification', error);
          resData.message = getErrorMessage(error.code);
        });
    }

    resData.isSucceeded = true;
    resData.itemDetails = res.user;
    resData.message = 'Sign up successful!';
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: firebaseCreateUser', error);
    resData.message = getErrorMessage(error.code);
    return resData;
  }
};

// export const getFirebaseToken = async () => {
//   try {
//     await getIdToken
//   } catch (error) {

//   }

// }

export const firebaseGetUser = async () => {
  try {
    await onAuthStateChanged(firebaseAuth, user => {
      // console.log('Fuser', user);
      if (user) {
        return user;
      } else {
        // console.log('no user');
        return null;
      }
    });
  } catch (error: any) {
    console.error(error);
    alert(error.message);
  }
};

export const firebasePasswordReset = async (email: string) => {
  try {
    const res = await sendPasswordResetEmail(firebaseAuth, email);
    // console.log('ress', res);
    alert('Password reset link sent!');
  } catch (error: any) {
    console.error(error);
    alert(error.message);
  }
};

export const firebaseUpdateNameAndPicture = async (payload: any) => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };

  try {
    if (!firebaseAuth.currentUser) {
      console.error('[ERROR] no current user.');
    }
    if (firebaseAuth.currentUser) {
      await updateProfile(firebaseAuth.currentUser, payload);
      resData.isSucceeded = true;
      resData.message = 'Update successful!';
    }
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: firebaseUpdateNameAndPicture', error);
    resData.message = getErrorMessage(error.code);
    return resData;
  }
};

export const firebaseUpdateEmail = async (email: string) => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };
  try {
    if (!firebaseAuth.currentUser) {
      console.error('[ERROR] no current user.');
    }
    if (firebaseAuth.currentUser) {
      await updateEmail(firebaseAuth.currentUser, email);
      resData.isSucceeded = true;
      resData.message = 'Update successful!';
    }
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: firebaseUpdateEmail', error);
    resData.message = getErrorMessage(error.code);
    return resData;
  }
};

export const firebaseUpdatePassword = async (password: string) => {
  const resData: IResponse = {
    isSucceeded: false,
    message: '',
    error: {},
    itemDetails: {}
  };
  try {
    if (!firebaseAuth.currentUser) {
      console.error('[ERROR] no current user.');
    }
    if (firebaseAuth.currentUser) {
      await updatePassword(firebaseAuth.currentUser, password);
      resData.isSucceeded = true;
      resData.message = 'Update successful!';
    }
    return resData;
  } catch (error: any) {
    console.error('[ERROR]: firebaseUpdateEmail', error);
    resData.message = getErrorMessage(error.code);
    return resData;
  }
};

export const firebaseSignOut = async () => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      await signOut(firebaseAuth);
    }
  } catch (error: any) {
    console.error('[ERROR] firebaseSignOut', error);
  }
};

export const firebaseDeleteUser = async () => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      await deleteUser(user);
    }
  } catch (error: any) {
    console.error('[ERROR] firebaseDeleteUser', error);
  }
};
