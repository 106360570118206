import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { firebaseAuth } from '../config/firebase';
import { firebaseUpdatePassword } from '../services';
import {
  systemSelector,
  setNotiData,
  checkUserSession,
  setPasswordFormDisplay
} from '../redux/system.slice';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, firebaseUser, isPasswordFormOpen } = useSelector(systemSelector);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (password === '') {
        setErrorMessage("Password can't be empty");
        return;
      }

      const serviceRes: any = await firebaseUpdatePassword(password);
      if (serviceRes.isSucceeded) {
        dispatch(setPasswordFormDisplay(false));
        dispatch<any>(checkUserSession());
        dispatch(setNotiData({ message: 'Update successful!', severity: 'success' }));
      } else {
        setErrorMessage(serviceRes.message);
        // dispatch(
        //   setNotiData({
        //     message: '',
        //     severity: 'error'
        //   })
        // );
      }
    } catch (error) {}
  };

  return (
    <Grid item xs={12} sx={{ marginTop: '12px' }}>
      <Dialog
        open={isPasswordFormOpen}
        onClose={() => {
          dispatch(setPasswordFormDisplay(false));
        }}
        maxWidth={'lg'}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin='normal'
            required
            fullWidth
            type='password'
            id='password'
            label='New Password'
            name='password'
            autoComplete='password'
            variant='standard'
            onChange={e => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setPasswordFormDisplay(false));
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
