import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Star, TrendingUp } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Stack, Button, Container, Avatar, Card, CardContent } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { getApps } from '../services';
import AllApp from '../images/allapp.png';

import { ENABLE_AXIOS_CANCEL_TOKEN, POINTS_OPTIONS } from '../constants';
import { setpageTitle } from '../redux/system.slice';
import { getTopUsers, getRegions } from '../services';
import { firebaseAuth, firebaseStorage } from '../config/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import LeaderboardTab from '../components/LeaderboardTab';
import AppMiniCard from '../components/AppCard/AppMiniCard';
import { systemSelector } from '../redux/system.slice';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

type PointsButtonProps = {
  pointsOption: string;
  isActive: boolean;
  onClick: () => void;
};

interface Item {
  _id: string;
  logo: string;
  imgUrl?: string;
}

function PointsButton({ pointsOption, isActive, onClick }: PointsButtonProps) {
  return (
    <Button
      variant='contained'
      sx={{
        borderRadius: '10px', // set the border radius to a smaller value
        fontWeight: 'bold',
        textTransform: 'none',
        bgcolor: isActive ? 'primary.main' : 'background.default',
        color: isActive ? 'background.paper' : 'text.primary'
      }}
      onClick={onClick}
    >
      {pointsOption === POINTS_OPTIONS[0] ? 'Total Points' : 'Weekly Points'}
    </Button>
  );
}

export default function Leaderboard() {
  const dispatch = useDispatch();
  const { firebaseUser, ottUser } = useSelector(systemSelector);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [regionRows, setRegionRows] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [pointsOption, setPointsOption] = useState(POINTS_OPTIONS[0]);
  const [rows, setRows] = useState<Item[]>([]);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [selectedApp, setSelectedApp] = useState<string>('');
  const [appLoading, setAppLoading] = useState<boolean>(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // function to handle the points button click
  const handlePointsButtonClick = (option: any) => {
    if (option === POINTS_OPTIONS[0]) setPointsOption(POINTS_OPTIONS[0]);
    if (option === POINTS_OPTIONS[1]) setPointsOption(POINTS_OPTIONS[1]);
  };

  const handleCardClick = (idx: number, id: string) => {
    if (selectedIdx === idx) {
      setSelectedIdx(null); // deselect the clicked card if it was already selected
    } else {
      setSelectedIdx(idx); // select the clicked card
      setSelectedApp(id);
    }
  };

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();
    const axiosCancelToken = axiosSource.token;

    const fetchApps = async () => {
      const serviceRes: any = await getApps(axiosCancelToken);
      if (serviceRes.isSucceeded) {
        setRows(serviceRes.itemList);
      } else {
        console.log('[INFO] No Apps Data');
      }
      setIsDataLoaded(true);
    };

    const fetchRegionRows = async () => {
      const serviceRes = await getRegions();
      if (serviceRes.isSucceeded) {
        setRegionRows(serviceRes.itemList);
      } else {
        console.log('[INFO] No region data');
      }
      setIsDataLoaded(true);
    };

    if (!isDataLoaded) {
      fetchRegionRows();
      fetchApps();
    }
  }, [isDataLoaded]);

  useEffect(() => {
    const getImageUrls = async (items: Item[]) => {
      const updatedItems = await Promise.all(
        items.map(async item => {
          if (item.logo) {
            const url = await getDownloadURL(ref(firebaseStorage, item.logo));
            const imgUrl = `${url}?alt=media`;
            return { ...item, imgUrl };
          }
          return item;
        })
      );
      setRows(updatedItems);
      setAppLoading(false);
    };

    if (rows.length > 0 && appLoading === true) {
      getImageUrls(rows);
    }
  }, [rows]);

  function stringToColor(string: any) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: any) {
    return name
      ? {
          sx: {
            bgcolor: stringToColor(name)
          },
          children: `${name.split(' ')[0][0]}`
        }
      : {};
  }

  return (
    <Grid container spacing={2} alignItems='center' sx={{ marginTop: '12px' }}>
      <Typography
        sx={{
          margin: '0px',
          fontSize: '20px',
          fontWeight: 'bold',
          flex: 1,
          textAlign: 'center',
          lineHeight: '1.5em'
        }}
        component='h5'
      >
        My Stats
      </Typography>
      <Grid
        container
        sx={{
          marginTop: '12px',
          minWidth: 300,
          fontFamily: 'Roboto Mono, monospace',
          fontWeight: 'bold',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        aria-label='simple grid'
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            fontSize: 18
          }}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>Tatthar:</Typography>
          <Avatar {...stringAvatar(firebaseUser?.displayName)} />
          {firebaseUser?.displayName}
        </Grid>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Star sx={{ marginRight: 1 }} />
                <Typography
                  sx={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: 16 }}
                >
                  Total Points
                </Typography>
              </Box>
              <Typography variant='h6'>{ottUser?.totalPoints}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <TrendingUp sx={{ marginRight: 1 }} />
                <Typography
                  sx={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: 16 }}
                >
                  Weekly Points
                </Typography>
              </Box>
              <Typography variant='h6'>{ottUser?.totalTitlePoints}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={12} md={8}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography
              sx={{
                margin: '0px',
                fontSize: '16px',
                fontWeight: 'bold',
                flex: 1,
                fontStyle: 'italic',
                textAlign: 'center',
                padding: '20px',
                lineHeight: '1.5em'
              }}
              component='h5'
            >
              "Success is not final, failure is not fatal: it is the courage to continue
              that counts."
              <br />- Winston Churchill
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap={2}
            justifyContent='center'
          >
            <PointsButton
              pointsOption={POINTS_OPTIONS[0]}
              isActive={pointsOption === POINTS_OPTIONS[0]}
              onClick={() => handlePointsButtonClick(POINTS_OPTIONS[0])}
            />
            <PointsButton
              pointsOption={POINTS_OPTIONS[1]}
              isActive={pointsOption === POINTS_OPTIONS[1]}
              onClick={() => handlePointsButtonClick(POINTS_OPTIONS[1])}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 5,
          marginBottom: 2
        }}
      >
        <Typography
          sx={{
            margin: '0px',
            fontSize: '16px',
            fontWeight: 'bold',
            flex: 1,
            fontStyle: 'italic',
            textAlign: 'center',
            padding: '20px',
            lineHeight: '1.5em'
          }}
          component='h5'
        >
          "Success is not final, failure is not fatal: it is the courage to continue that
          counts."
          <br />- Winston Churchill
        </Typography>

        <Stack direction='row' spacing={2}>
          <PointsButton
            pointsOption={POINTS_OPTIONS[0]}
            isActive={pointsOption === POINTS_OPTIONS[0]}
            onClick={() => handlePointsButtonClick(POINTS_OPTIONS[0])}
          />
          <PointsButton
            pointsOption={POINTS_OPTIONS[1]}
            isActive={pointsOption === POINTS_OPTIONS[1]}
            onClick={() => handlePointsButtonClick(POINTS_OPTIONS[1])}
          />
        </Stack>
      </Container> */}
      <Container sx={{ marginTop: 5 }}>
        <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
          <Stack direction='row' spacing={2} style={{ marginLeft: 4, marginRight: 4 }}>
            <AppMiniCard
              imageUrl={AllApp}
              title='All'
              key={0}
              selected={selectedIdx === 0}
              onClick={() => handleCardClick(0, '')}
            />
            {rows
              .filter((row: any) => row.name !== 'Web Central')
              .map((row: any, idx: number) => (
                <AppMiniCard
                  imageUrl={row.imgUrl}
                  title={row.name}
                  key={idx + 1}
                  selected={selectedIdx === idx + 1}
                  onClick={() => handleCardClick(idx + 1, row._id)}
                />
              ))}
          </Stack>
        </div>
      </Container>
      {regionRows.length < 0 && (
        <Paper
          sx={{
            p: 2,
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: 240
          }}
        >
          No region found
        </Paper>
      )}
      {regionRows.length > 0 && (
        <Paper
          sx={{
            p: 2,
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: 240,
            marginBottom: '12px'
          }}
        >
          <Box
            sx={{ bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
              sx={{ width: '100%' }}
              TabIndicatorProps={{ style: { backgroundColor: '#ffd700' } }}
            >
              <Tab
                key={`menu-tab-${0}`}
                label='Global'
                sx={{
                  color: tabValue === 0 ? '#ffd700' : 'text.secondary',
                  fontSize: 18,
                  fontWeight: 'bold'
                }}
              />
              {regionRows.map((reg: any, index: number) => {
                return (
                  <Tab
                    key={`menu-tab-${index + 1}`}
                    label={reg.name}
                    sx={{
                      color: tabValue === index + 1 ? '#ffd700' : 'text.secondary',
                      fontSize: 18
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>

          <LeaderboardTab
            key={`tab-content-${0}`}
            tabValue={tabValue}
            tabIndex={0}
            regionId='global'
            regionName='Global'
            pointsOption={pointsOption}
            appId={selectedApp}
          />

          {regionRows.map((reg: any, index: number) => {
            return (
              <LeaderboardTab
                key={`tab-content-${index + 1}`}
                tabValue={tabValue}
                tabIndex={index + 1}
                regionId={reg._id}
                regionName={reg.name}
                pointsOption={pointsOption}
                appId={selectedApp}
              />
            );
          })}
        </Paper>
      )}
    </Grid>
  );
}
