import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

import { systemSelector, setDisableNotiBar } from '../../redux/system.slice';

export default function SimpleSlide() {
  const dispatch = useDispatch();

  const { notiData, isNotiBarOpen } = useSelector(systemSelector);

  const handleCloseAction = () => {
    dispatch(setDisableNotiBar());
  };

  useEffect(() => {
    if (isNotiBarOpen) {
      setInterval(() => {
        dispatch(setDisableNotiBar());
      }, 6000);
    }
  }, [dispatch, isNotiBarOpen]);

  return (
    <Box
      id='noti-bar-box'
      sx={{
        padding: '0px',
        margin: '0px',
        width: '100%',
        display: isNotiBarOpen ? 'flex' : 'none',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'absolute',
        top: '76px',
        right: '24px',
        zIndex: 1
      }}
    >
      <Slide direction='left' in={isNotiBarOpen}>
        <Box id='alert-container' sx={{ maxWidth: '320px', minWidth: '320px' }}>
          {!!notiData && notiData.severity === 'error' && (
            <Alert
              icon={false}
              variant='filled'
              severity='error'
              onClose={() => {
                handleCloseAction();
              }}
            >
              {notiData.message}
            </Alert>
          )}
          {!!notiData && notiData.severity === 'warning' && (
            <Alert
              icon={false}
              variant='filled'
              severity='warning'
              onClose={() => {
                handleCloseAction();
              }}
            >
              {notiData.message}
            </Alert>
          )}
          {!!notiData && notiData.severity === 'info' && (
            <Alert
              icon={false}
              variant='filled'
              severity='info'
              onClose={() => {
                handleCloseAction();
              }}
            >
              {notiData.message}
            </Alert>
          )}
          {!!notiData && notiData.severity === 'success' && (
            <Alert
              icon={false}
              variant='filled'
              severity='success'
              onClose={() => {
                handleCloseAction();
              }}
            >
              {notiData.message}
            </Alert>
          )}
        </Box>
      </Slide>
    </Box>
  );
}
