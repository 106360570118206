import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { setpageTitle } from '../redux/system.slice';
import { ENABLE_AXIOS_CANCEL_TOKEN } from '../constants';
import { getApps } from '../services';

export default function Users() {
  const dispatch = useDispatch();

  // const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  // const [rows, setRows] = useState([]);

  return (
    <Grid id='admin-users-container' item xs={12}>
      <Typography
        sx={{
          margin: '12px 0px 12px 0px',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        component='h5'
      >
        Users
      </Typography>

      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 240
        }}
      >
        This is Users Page.
      </Paper>
    </Grid>
  );
}
